import { Grid, Typography } from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

export const AligningStripsTipsComponent = ({ setShowTip, handleBackButton }) => {
  return (
    <div>
      <CloseIcon
        style={{
          color: "#FFFFFF",
          cursor: "pointer",
          float: "right",
          margin: "6",
        }}
        onClick={() => setShowTip(false)}
      />
      <Grid container>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          style={{ paddingLeft: "30px" }}
        >
          <Grid
            item
            style={{
              display: "flex",
              paddingBottom: "12px",
              alignItems: "center",
            }}
          >
            <ArrowBackIcon style={{ color: "white", cursor: "pointer", marginRight: "8px" }} onClick={() => handleBackButton()}/>
            <Typography
              style={{
                color: "#FFFFFF",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0.1px",
              }}
            >
              Aligning strips
            </Typography>
          </Grid>
          <Typography
            style={{
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: "0.1px",
            }}
          >
            If the strip is not aligned, select the strip and use the left and right arrow keys to move it and align.
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            marginLeft: "30px",
            marginBottom: "30px",
            marginTop: "20px",
          }}
        >
          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
              style={{ width: "700px", height: "auto" }}
                src={require("../../constants/04CwoTEXT.gif")}
                alt="filledStateImage"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
