import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Typography from '@material-ui/core/Typography';

export const TipsSheetComponent = (props) => {
  const [showTip, setShowTip] = React.useState(true)
  const { currentPageTitle } = props
  return (
    showTip && <Paper
      elevation={4}
      style={{
        overflow: "auto",
        height: "auto",
        background: "#034CAC",
        position: "fixed",
        marginBottom: "30px",
        marginRight: "30px",
        bottom: 0,
        right: 0,
        zIndex: 100
      }}
    >
      <CloseIcon
        style={{
          color: "#FFFFFF",
          cursor: "pointer",
          float: "right",
          margin: "6",
        }}
        onClick={() => setShowTip(false)}
      />
      <Grid container direction="column" alignItems="flex-start" style={{ marginLeft: "30px" }}>
        <Typography
          style={{
            color: "#FFFFFF",
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            paddingBottom: "12px"
          }}
        >
          {currentPageTitle.tipsObject.headerText}
        </Typography>
        {currentPageTitle?.tipsObject?.optionalListOfInstructions && currentPageTitle.tipsObject.optionalListOfInstructions.map((tip, index) =>
          <React.Fragment key={index}>
            <Grid item style={{ display: "flex", paddingBottom: "20px" }}>
              {tip.bulletPoint && <Typography
                style={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "0.1px",
                }}
              >
                {currentPageTitle.tipsObject.optionalListOfInstructions.length > 1 ? index + 1 + ". " : ""} {tip.bulletPoint}
              </Typography>}
            </Grid>
            <Grid item style={{ backgroundColor: "#FFFFFF", borderRadius: "1%", marginBottom: 20 }}>
              <img
                alt={tip.bulletPoint}
                style={{ width: "700px", height: "auto" }}
                src={tip.imageSource}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Paper>
  );
};
