import Draggable from 'react-draggable';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  coveringCanvas: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    cursor: "pointer",
    // zIndex: "3"
    // backgroundColor: 'yellow'
  }
}));

const DraggableStrip = ({ image, pos, setPos }) => {
  const classes = useStyles();
  const canvas = React.useRef();

  function getMousePos (canvas, evt) {
    var rect = canvas.getBoundingClientRect();
    var scaleX = canvas.width / rect.width;
    // var scaleY = canvas.height / rect.height;
    setPos((evt.clientX - rect.left) * scaleX)
  }

  const handleChange = (e) => {
    getMousePos(canvas.current, e)
  }
  React.useEffect(() => {
    const drawLine = (info, style = {}) => {
      const canvasEle = canvas.current;
      const ctx = canvasEle?.getContext("2d");
      const { x, y, x1, y1 } = info;
      const { color = '#429637', width = "2" } = style;
      if (ctx) {
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.lineWidth = width;
        ctx.moveTo(x, y);
        ctx.lineTo(x1, y1);
        ctx.clearRect(0, 0, canvasEle.width, canvasEle.height);
        ctx.stroke();
      }
    }
    drawLine({ x: pos, y: 0, x1: pos, y1: 150 });
  }, [pos])

  return (
    <React.Fragment>
      <div style={{ marginLeft: "30px", marginRight: "30px" }}>
        <Draggable axis="x" bounds={{ left: -30, right: 30 }}>
          <div>
            <img style={{ width: "100%", height: "100%" }}
                 src={image} alt="strips"/>
            <canvas className={classes.coveringCanvas} ref={canvas} onMouseMove={handleChange}
            />
          </div>
        </Draggable>
      </div>
    </React.Fragment>
  );
}

export default DraggableStrip;
