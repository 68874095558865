import {
  SET_BATCH_ID,
  SET_BATCH_INFO_FOR_DASHBOARD,
  SET_UPLOADED_SHEETS,
  SET_BATCH_ROI,
  SET_BATCH_VALIDITY,
  SET_INTERPRETATION,
  SET_QUALITY_CHECK,
  SET_QUALITY_CHECK_ERROR,
  SET_SUMMARY,
  SET_USER_INFO
} from "./types";
import { batchApi, dashboardApi, interpretationApi, sheetApi, userApi } from "../env";

import fetchApi from "./fetchApi"

export function setBatchId (data) {
  return {
    type: SET_BATCH_ID,
    data
  };
}

export function setQualityCheck (data) {
  return {
    type: SET_QUALITY_CHECK,
    data
  };
}

export function setQualityCheckError (data) {
  return {
    type: SET_QUALITY_CHECK_ERROR,
    data
  };
}

export function setSheetsInBatch (data) {
  return {
    type: SET_UPLOADED_SHEETS,
    data
  };
}

export function setBatchRoi (data) {
  return {
    type: SET_BATCH_ROI,
    data
  };
}

export function setInterpretation (data) {
  return {
    type: SET_INTERPRETATION,
    data
  };
}

export function setBatchValidity (data) {
  return {
    type: SET_BATCH_VALIDITY,
    data
  };
}

export function setSummaryData (data) {
  return {
    type: SET_SUMMARY,
    data
  };
}

export function setUserInfo (data) {
  return {
    type: SET_USER_INFO,
    data
  };
}

export function setBatchInfoForDashboard (data) {
  return {
    type: SET_BATCH_INFO_FOR_DASHBOARD,
    data
  };
}

export function createBatch (batchType, userId) {
  // console.log("batchType", batchType)
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("batch_type", batchType);
  return dispatch => {
    return fetchApi(batchApi, "POST", formdata)
      .then(res => {
        dispatch(setBatchId(res?.data));
      });
  };
}

export function getQualityCheck (batchId, image) {
  var formdata = new FormData();
  formdata.append("batch_id", batchId);
  formdata.append("image", image)
  return dispatch => {
    return fetchApi(sheetApi, "POST", formdata)
      .then(res => {
        res?.success ? dispatch(setQualityCheck(res?.data)) : dispatch(setQualityCheckError(res?.detail));
      });
  };
}

export function deleteSheet (params = {}) {
  return () => {
    return fetchApi(sheetApi, "DELETE", params)
      .then(res => {
        // console.log(res?.data)
      });
  };
}

export function submitSheet (sheetId) {
  // console.log("sheetId", sheetId)
  return () => {
    return fetchApi(sheetApi + sheetId, "PUT")
      .then(() => {
        // console.log(res?.data)
      });
  };
}

export function reportSheet (sheetId, reportReason) {
  var formdata = new FormData();
  formdata.append("report_reason", reportReason);
  return () => {
    return fetchApi(sheetApi + 'report/' + sheetId, "PUT", formdata)
      .then(() => {
        // console.log(res?.data)
      });
  };
}

export function getSheetsInBatch (batchId) {
  return dispatch => {
    return fetchApi(batchApi + "sheets/" + batchId, "GET")
      .then(res => {
        dispatch(setSheetsInBatch(res?.data));
      });
  };
}

export function getBatchRoi (batchId) {
  return dispatch => {
    return fetchApi(batchApi + batchId, "GET")
      .then(res => {
        dispatch(setBatchRoi(res?.data));
      });
  };
}

export function submitLabIdsForBatch (batchId, sheets) {
  // console.log("submit labId", batchId)

  const batchRoi = async () => {
    const request = await fetchApi(batchApi + batchId, "PUT", JSON.stringify({ sheets: sheets }), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

  const batchValidity = async () => {
    const request = await fetchApi(batchApi + 'validate/' + batchId, "GET", {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

    return async dispatch => {
      await batchRoi();
      const data = await batchValidity();
      dispatch(setBatchValidity(data?.data));
    };
  };

  export function getBatchValidity (batchId) {
    return dispatch => {
      return fetchApi(batchApi + 'validate/' + batchId, "GET")
        .then(res => {
          dispatch(setBatchValidity(res?.data));
        });
    };
  }

export function getInterpretation (batchId, batchValidity, updateText) {
  updateText = updateText === '' ? 'non' : updateText
  var formdata = new FormData();
  formdata.append("is_valid", batchValidity);
  formdata.append("validity_update_reason", updateText);
  const batchValidityPut = async () => {
    const request = await fetchApi(batchApi + 'validate/' + batchId, "PUT", formdata);
    const data = await request;
    return data;
  };

  const getInterpretationApi = async () => {
    const request = await fetchApi(interpretationApi + batchId, "GET", {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

    return async dispatch => {
      await batchValidityPut();
      const data = await getInterpretationApi();
      dispatch(setInterpretation(data?.data));
    };
}

export function getInterpretationData (batchId) {
  return dispatch => {
    return fetchApi(interpretationApi + batchId, "GET")
      .then(res => {
        dispatch(setInterpretation(res?.data));
      });
  };
}


export function getSummary (batchId, batchValidity, updateText, makeCall) {
  updateText = updateText === '' ? 'non' : updateText
  const batchValidityPut = async () => {
    var formdata = new FormData();
    formdata.append("is_valid", batchValidity);
    formdata.append("validity_update_reason", updateText);
    const request = await fetchApi(batchApi + 'validate/' + batchId, "PUT", formdata);
    const data = await request;
    return data;
  };

  const getSummaryApi = async () => {
    const request = await fetchApi(batchApi + 'summary/' + batchId, "GET", {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

    return async dispatch => {
      if (makeCall) {
        await batchValidityPut()
      }
      const data = await getSummaryApi();
      dispatch(setSummaryData(data?.data));
    };
}

export function submitInterpretation (batchId, updatedRows) {
  const submitInterpretationApi = async () => {
     const request = await fetchApi(interpretationApi + batchId, "PUT", JSON.stringify({ updated_rows: updatedRows }), {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };
  const getSummaryApi = async () => {
    const request = await fetchApi(batchApi + 'summary/' + batchId, "GET", {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    const data = await request;
    return data;
  };

  return async dispatch => {
    await submitInterpretationApi()
    const data = await getSummaryApi();
    dispatch(setSummaryData(data?.data));
  };
}

export function getUserInfo (userId) {
  return dispatch => {
    return fetchApi(userApi + userId, "GET")
      .then(res => {
        dispatch(setUserInfo(res?.data));
      });
  };
}

export function getBatchInfoForDashboard (userId) {
  return dispatch => {
    return fetchApi(dashboardApi + userId, "GET")
      .then(res => {
        dispatch(setBatchInfoForDashboard(res?.data));
      });
  };
}
