import CloseIcon from "@material-ui/icons/Close";
import { Grid, Typography } from "@material-ui/core";
import React from "react";

export const LowConfidenceTipsComponent = ({ setShowTip }) => {
  return (
    <div>
      <CloseIcon
        style={{
          color: "#FFFFFF",
          cursor: "pointer",
          float: "right",
          margin: "6",
        }}
        onClick={() => setShowTip(false)}
      />
      <Grid container>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          style={{ paddingLeft: "30px" }}
        >
          <Grid
            item
            style={{
              display: "flex",
              paddingBottom: "12px",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#FFFFFF",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "0.1px",
              }}
            >
              Low confidence bands
            </Typography>
          </Grid>
          <Typography
            style={{
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: "0.1px",
            }}
          >
            Orange Marker
          </Typography>
          <Typography
            style={{
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: "0.1px",
            }}
          >
            Confirm if the algorithms interpretation for the band is right
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            marginLeft: "30px",
            marginBottom: "30px",
            marginTop: "20px",
            marginRight: "30px",
          }}
        >
          <Grid item xs={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={require("../../constants/filledStateImage.png")}
                alt="filledStateImage"
                style={{ width: "62px", height: "62px" }}
              />
              <div style={{ paddingLeft: "12px", color: "white" }}>
                Filled state: Algorithm thinks the band is present, but isnt
                sure.
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={require("../../constants/emptyStateImage.png")}
                alt="filledStateImage"
                style={{ width: "62px", height: "62px" }}
              />
              <div style={{ paddingLeft: "12px", color: "white" }}>
                Empty state: Algorithm thinks the band is absent, but isnt
                sure.
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
