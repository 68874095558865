import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import BatchValidity from "../pages/BatchValidity";
import Dashboard from "../pages/Dashboard";
import Interpretation from "../pages/Interpretation";
import MatchLabIds from "../pages/MatchLabIds";
import React from "react";
import Summary from "../pages/InterpretationSummary";
import Upload from "../pages/Upload";

const App = () => {
  const s = "/dashboard/"
  const [state, setState] = React.useState("d");
  function renderPage()
  {
    if (state === "u") {
      return <Upload setState={setState}/>
    } else
    if (state === "m") { return <MatchLabIds setState={setState}/> } else
    if (state === "b") { return <BatchValidity setState={setState}/> } else
    if (state === "i") { return <Interpretation setState={setState}/> } else
    if (state === "s") { return <Summary setState={setState}/> } else {
      return Array.from(Array(30).keys()).map((i) => (
        <Route exact path={s.concat(i + 1)} key={i + 1}>
        <Dashboard userId = {i + 1} setState={setState} />
        </Route>
      ));
    }
  }
 return (
    <Router>
      <Switch>
      {renderPage()}
      </Switch>
    </Router>
  );
}
export default App;
