/* eslint-disable react-hooks/exhaustive-deps */
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import DraggableStrip from '../components/draggableStrip';
// import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import Grid from '@material-ui/core/Grid';
// import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    icon: {
        borderRadius: 0,
        border: '1px solid #5F6368',
        width: 16,
        height: 16,
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
          },
      },
      checkedIcon: {
        borderRadius: 0,
        border: '1px solid #429637',
        width: 16,
        height: 16,
        backgroundColor: '#429637',
        'input:hover ~ &': {
            backgroundColor: '#429637',
          },
      },
      iconOrangeBorder: {
        borderRadius: 0,
        border: '2px solid #F57C00',
        width: 16,
        height: 16,
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
          },
      },
      checkedIconOrangeBorder: {
        borderRadius: 0,
        border: '2px solid #F57C00',
        width: 16,
        height: 16,
        backgroundColor: '#429637',
        'input:hover ~ &': {
            backgroundColor: '#429637',
          },
      },
      tableData: {
        color: "#1A73E8",
        fontSize: "26px",
        fontWeight: "400",
        letterSpacing: "0.15px"
      },
      invalidTestText: {
        color: "#C5221F",
        fontSize: "24px",
        fontWeight: "400",
        letterSpacing: "0.15px"
      },
      mtbNotDetectedText: {
        color: "#80868B",
        fontSize: "24px",
        fontWeight: "400",
        letterSpacing: "0.15px"
      }
}));

const InterpretationTable = ({ batchType, input, output, setInput, setOutput, inputToDisplay, outputToDisplay }) => {
    const [pos, setPos] = React.useState(0)
    // console.log("in the component", input, output)
    const classes = useStyles();
    function getRowIndex (sheetId, rowId) {
       return input.findIndex(row => row.sheetId === sheetId && row.rowId === rowId);
    }
    const handleBandChange = (sheetId, rowId, j) => (event) => {
        const i = getRowIndex(sheetId, rowId)
        input[i].bandData[j].bandPresent = !input[i]?.bandData[j]?.bandPresent
        if (batchType === 'FL') {
            output[i].resultData[0] = '';
            if (!input[i].bandData[0].bandPresent || !input[i].bandData[1].bandPresent) {
                output[i].resultData[0] = 'INVALID TEST';
            } else if (!input[i].bandData[2].bandPresent && (input[i].bandData[0].bandPresent && input[i].bandData[1].bandPresent)) {
                output[i].resultData[0] = 'MTB NOT DETECTED';
            } else if (input[i].bandData[2].bandPresent && (!input[i].bandData[3].bandPresent || !input[i].bandData[16].bandPresent || !input[i].bandData[20].bandPresent)) {
                output[i].resultData[0] = 'INDETERMINATE TEST';
            }
            if (output[i].resultData[0] === '') {
                output[i].resultData[0] = input[i].bandData[2].bandPresent ? '+' : '-'; // TUB
                output[i].resultData[1] = input[i].bandData.slice(4, 12).reduce(function (rpobWT, val) { return rpobWT && val.bandPresent; }, true) ? '+' : '-'; // rpobWT
                output[i].resultData[2] = input[i].bandData.slice(12, 16).reduce(function (rpobMUT, val) { return rpobMUT || val.bandPresent; }, false) ? '+' : '-'; // rpobMUT
                output[i].resultData[3] = input[i].bandData.slice(17, 18).reduce(function (katGWT, val) { return katGWT && val.bandPresent; }, true) ? '+' : '-'; // katGWT
                output[i].resultData[4] = input[i].bandData.slice(18, 20).reduce(function (katGMUT, val) { return katGMUT || val.bandPresent; }, false) ? '+' : '-'; // katGMUT
                output[i].resultData[5] = input[i].bandData.slice(21, 23).reduce(function (inhAWT, val) { return inhAWT && val.bandPresent; }, true) ? '+' : '-'; // inhAWT
                output[i].resultData[6] = input[i].bandData.slice(23, 27).reduce(function (inhAMUT, val) { return inhAMUT || val.bandPresent; }, false) ? '+' : '-'; // inhAMUT
                // RMP
                if (output[i].resultData[1] === '+' && output[i].resultData[2] === '-') {
                    output[i].resultData[7] = 'S'
                    output[i].resultData[8] = ''
                } else {
                    output[i].resultData[7] = ''
                    output[i].resultData[8] = 'R'
                }
                // INH
                if (output[i].resultData[3] === '+' && output[i].resultData[4] === '-' && output[i].resultData[5] === '+' && output[i].resultData[6] === '-') {
                    output[i].resultData[9] = 'S'
                    output[i].resultData[10] = ''
                } else {
                    output[i].resultData[9] = ''
                    output[i].resultData[10] = 'R'
                }
            }
        } else { // SL
            output[i].resultData[0] = '';
            if (!input[i].bandData[0].bandPresent || !input[i].bandData[1].bandPresent) {
                output[i].resultData[0] = 'INVALID TEST';
            } else if (!input[i].bandData[2].bandPresent && (input[i].bandData[0].bandPresent && input[i].bandData[1].bandPresent)) {
                output[i].resultData[0] = 'MTB NOT DETECTED';
            } else if (input[i].bandData[2].bandPresent && (!input[i].bandData[3].bandPresent || !input[i].bandData[13].bandPresent || !input[i].bandData[17].bandPresent || !input[i].bandData[22].bandPresent)) {
                output[i].resultData[0] = 'INDETERMINATE TEST';
            }

            if (output[i].resultData[0] === '') {
                output[i].resultData[0] = input[i].bandData[2].bandPresent ? '+' : '-'; // TUB
                // gyrAWT
                output[i].resultData[1] = input[i].bandData.slice(4, 7).reduce(function (gyrAWT, val) { return gyrAWT && val.bandPresent; }, true) ? '+' : '-';
                // gyrAMUT
                output[i].resultData[2] = input[i].bandData.slice(7, 13).reduce(function (gyrAMUT, val) { return gyrAMUT || val.bandPresent; }, false) ? '+' : '-';
                // gyrBWT
                output[i].resultData[3] = input[i].bandData.slice(14, 15).reduce(function (gyrBWT, val) { return gyrBWT && val.bandPresent; }, true) ? '+' : '-';
                // gyrBMUT
                output[i].resultData[4] = input[i].bandData.slice(15, 17).reduce(function (gyrBMUT, val) { return gyrBMUT || val.bandPresent; }, false) ? '+' : '-';
                // rrsWT
                output[i].resultData[5] = input[i].bandData.slice(18, 20).reduce(function (rrsWT, val) { return rrsWT && val.bandPresent; }, true) ? '+' : '-';
                // rrsMUT
                output[i].resultData[6] = input[i].bandData.slice(20, 22).reduce(function (rrsMUT, val) { return rrsMUT || val.bandPresent; }, false) ? '+' : '-';
                // eisWT
                output[i].resultData[7] = input[i].bandData.slice(23, 26).reduce(function (eisWT, val) { return eisWT && val.bandPresent; }, true) ? '+' : '-';
                // eisMUT
                output[i].resultData[8] = input[i].bandData.slice(26, 27).reduce(function (eisMUT, val) { return eisMUT || val.bandPresent; }, false) ? '+' : '-';

                // FLQ
                if (output[i].resultData[1] === '+' && output[i].resultData[2] === '-' && output[i].resultData[3] === '+' && output[i].resultData[4] === '-') {
                    output[i].resultData[9] = 'S'
                } else {
                    output[i].resultData[9] = 'R'
                }

                // KAN/AMK/CAP
                if (output[i].resultData[5] === '+' && output[i].resultData[6] === '-') {
                    output[i].resultData[10] = 'S'
                } else {
                    output[i].resultData[10] = 'R'
                }

                // low-level KAN
                if (output[i].resultData[7] === '+' && output[i].resultData[8] === '-') {
                    output[i].resultData[13] = 'S'
                } else {
                    output[i].resultData[13] = 'R'
                }
            }
        }
        setInput([...input])
        setOutput([...output])
      };

    return (
        <React.Fragment>
            {batchType === 'FL'
            ? <Grid container spacing={2} style={{ width: "1360px", display: "flex", height: "252px", paddingTop: "10px" }}>

                <Grid item xs={12} md={8} lg={9} style={{ display: "flex", flexDirection: "column", }}>
                    <div style={{ paddingLeft: "200px", width: "970px", paddingTop: "25px" }}>
                        <img alt="scale" style={{ width: "100%", height: "100%" }} src={require('../constants/ref_strip_FL.png')}></img>
                    </div>

                </Grid>
                <Grid item xs={12} md={4} lg={3} style={{ display: "flex", paddingTop: "40px" }}>
                        <Table className={classes.table} size="small" style={{ width: "350px", border: "1px solid #1A73E8", borderRadius: "0" }} padding="none" aria-label="simple table">
                            <TableRow>
                                <TableCell align="center" style={{ width: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "40px" }}>
                                        TUB
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "40px" }}>
                                    rpoB WT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "40px" }}>
                                    rpoB MUT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "40px" }}>
                                    katG WT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "40px" }}>
                                    katG MUT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "40px" }}>
                                        inhA WT
                                    </Typography>
                                </TableCell>

                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "40px" }}>
                                        inhA MUT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ maxWidth: "60px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <TableRow>
                                        <TableCell colSpan={2} align="center" style={{ height: "35px", borderBottom: "1px solid #1A73E8" }}>
                                            <Typography style={{ color: "#1A73E8" }}>
                                            RMP
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "0px" }}>
                                        <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "40px", fontSize: "15px" }}>
                                            Sensitive
                                        </Typography>
                                        </TableCell>
                                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "75px", borderBottom: "0px" }}>
                                        <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "40px", fontSize: "15px" }}>
                                            Resistent
                                        </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableCell>
                                <TableCell align="center" style={{ maxWidth: "60px", borderBottom: "1px solid #1A73E8" }}>
                                    <TableRow>
                                        <TableCell colSpan={2} align="center" style={{ height: "35px", borderBottom: "1px solid #1A73E8" }}>
                                            <Typography style={{ color: "#1A73E8" }}>
                                            INH
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "75px", borderRight: "1px solid #1A73E8", borderBottom: "0px" }}>
                                        <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "40px", fontSize: "15px" }}>
                                        Sensitive
                                    </Typography>
                                        </TableCell>
                                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderBottom: "0px" }}>
                                        <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "40px", fontSize: "15px" }}>
                                        Resistent
                                    </Typography>
                                        </TableCell>
                                </TableCell>

                            </TableRow>
                        </Table>
                </Grid>

            </Grid>

            : <Grid container spacing={2} style={{ width: "1360px", display: "flex", height: "241px", paddingTop: "10px" }}>

                <Grid item xs={12} md={8} lg={8} style={{ display: "flex", flexDirection: "column", }}>
                    <div style={{ paddingLeft: "105px", width: "860px", paddingTop: "40px" }}>
                        <img alt="scale" style={{ width: "100%", height: "100%" }} src={require('../constants/ref_strip_SL.png')}></img>
                    </div>

                </Grid>
                <Grid item xs={12} md={4} lg={4} style={{ display: "flex" }}>
                        <Table className={classes.table} size="small" style={{ width: "350px", border: "1px solid #1A73E8", borderRadius: "0" }} padding="none" aria-label="simple table">
                            <TableRow>
                                <TableCell align="center" style={{ width: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "130px" }}>
                                        TUB
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "130px" }}>
                                    gyrA WT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "130px" }}>
                                    gyrA MUT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "130px" }}>
                                    gyrB WT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "130px" }}>
                                    gyrB MUT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "130px" }}>
                                        rrs WT
                                    </Typography>
                                </TableCell>

                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "130px" }}>
                                        rrs MUT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "130px" }}>
                                        eis WT
                                    </Typography>
                                </TableCell>

                                <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", whiteSpace: "nowrap", marginTop: "130px" }}>
                                        eis MUT
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" style={{ maxWidth: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                    <TableRow>
                                        <TableCell colSpan={5} align="center" style={{ height: "35px", borderBottom: "1px solid #1A73E8" }}>
                                            <Typography style={{ color: "#1A73E8", fontSize: '15px' }}>
                                            Phenotypic resistance
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "0px" }}>
                                        <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "90px", fontSize: "13px" }}>
                                        FLQ
                                        </Typography>
                                        </TableCell>
                                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "130px", borderBottom: "0px", borderRight: "1px solid #1A73E8" }}>
                                        <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "90px", fontSize: "13px" }}>
                                        KAN/AMK/CAP
                                        </Typography>
                                        </TableCell>
                                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", borderRight: "1px solid #1A73E8", borderBottom: "0px" }}>
                                        <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "90px", fontSize: "13px" }}>
                                        KAN/CAP/VIO
                                        </Typography>
                                        </TableCell>
                                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "75px", borderBottom: "0px", borderRight: "1px solid #1A73E8" }}>
                                        <Typography style={{ transform: `rotate(-90deg)`, color: "#1A73E8", marginTop: "90px", fontSize: "13px" }}>
                                        KAN/AMK/CAP/VIO
                                        </Typography>
                                        </TableCell>
                                        <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "75px", borderBottom: "0px" }}>
                                        <Typography style={{ transform: `rotate(-90deg)`, whiteSpace: "nowrap", color: "#1A73E8", marginTop: "90px", fontSize: "13px" }}>
                                        low-level KAN
                                        </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableCell>

                            </TableRow>
                        </Table>
                </Grid>

            </Grid>
            }
{batchType === 'FL'
                    ? <Grid container spacing={2} style={{ width: "1360px", display: "flex", height: "600px", overflowY: 'overlay', paddingBottom: "20px" }}>
                    <Grid item xs={12} md={8} lg={9} style={{ display: "flex", flexDirection: "column", marginBottom: "435px" }}>

                  {inputToDisplay.map((row) => (
                  <div>
                      <Grid container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        <Grid item lg={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ alignSelf: "center" }}>
                                {/* <InfoIcon style={{ color: "#FB8C00" }} fontSize="small"/>
                                <FlagOutlinedIcon style={{ color: "#9AA0A6" }} fontSize="small"/> */}
                                <Typography>
                                    Sheet {row.sheetNo}
                                </Typography>
                                <Typography>
                                    Sr. No. : {row.position}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg ={10} style={{ display: "flex", flexDirection: "column", height: "110px" }}>
                            <DraggableStrip pos={pos} setPos={setPos} image={row.stripImagePath}></DraggableStrip>
                            <div style={{ paddingLeft: "50px", display: "flex", zIndex: "2" }} id = {row.position}>
                          {row?.bandData?.map((j, index) => {
                              if (index <= 26) {
                              return (
                                // , paddingRight: index === 2 || index === 15 || index === 19 ? "30px" : "0px"
                             <Grid container alignItems="center" display="flex" direction="column" style={{ width: "23px", paddingRight: index === 2 || index === 15 || index === 19 ? "40px" : "2px" }}>
                                    <Grid item>
                                    { j.confidence === false
                                    ? <Divider orientation="vertical" style={{ marginTop: "-20px", marginBottom: "-10px", height: "30px", background: "#F57C00", width: "2px" }}/>
                                    : ''}
                                    </Grid>
                                    <Grid item>
                                        <Checkbox
                                            className={classes.root}
                                            disableRipple
                                            checked = {j.bandPresent}
                                            icon={<span className={j.confidence === true ? classes.icon : classes.iconOrangeBorder} />}
                                            checkedIcon={<span className={j.confidence === true ? classes.checkedIcon : classes.checkedIconOrangeBorder} />}
                                            onChange={handleBandChange(row.sheetId, row.rowId, index)}
                                        />
                                    </Grid>
                              </Grid>
                          )
 }
 return true
})}
                          </div>

                          </Grid>
                      </Grid>
                  <Divider />
                  </div>
                  ))}
              </Grid>

                     <Grid item xs={12} md={4} lg={3} style={{ display: "flex", marginBottom: "435px" }}>

                         <Table className={classes.table} size="small" style={{ width: "350px", border: "1px solid #1A73E8", borderRadius: "0" }} padding="none" aria-label="simple table">
                                  <TableBody>
                                  {outputToDisplay.map((row, i) => {
                                      return (
                                            row.resultData[0] === 'MTB NOT DETECTED'
                                           ? <TableRow key={i}>
                                           <TableCell colSpan={11} align="center" style={{ width: "350px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                           <Typography className={classes.mtbNotDetectedText}>{row.resultData[0]}</Typography>
                                           </TableCell>
                                           </TableRow>
                                           : row.resultData[0] === 'INVALID TEST' || row.resultData[0] === 'INDETERMINATE TEST'
                                           ? <TableRow key={i}>
                                           <TableCell colSpan={11} align="center" style={{ height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                           <Typography className={classes.invalidTestText}>{row.resultData[0]}</Typography>
                                            </TableCell>
                                            </TableRow>
                                           : <TableRow key={i}>
                                           <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[0]}</Typography>
                                          </TableCell>
                                          <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[1]}</Typography>
                                          </TableCell>
                                          <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[2]}</Typography>

                                          </TableCell>
                                          <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[3]}</Typography>

                                          </TableCell>
                                          <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[4]}</Typography>
                                          </TableCell>
                                          <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[5]}</Typography>
                                          </TableCell>
                                          <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[6]}</Typography>
                                          </TableCell>
                                          <TableCell align="center" style={{ minWidth: "60px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                              <TableRow>
                                                  <TableCell align="center" style={{ width: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[7]}</Typography>

                                                  </TableCell>
                                                  <TableCell align="center" style={{ width: "30px", maxWidth: "30px", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[8]}</Typography>

                                                  </TableCell>
                                              </TableRow>
                                          </TableCell>
                                          <TableCell align="center" style={{ minWidth: "60px", height: "150px", borderBottom: "1px solid #1A73E8" }}>
                                              <TableRow>
                                                  <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", color: "#1A73E8" }}>
                                                    <Typography className={classes.tableData}>{row.resultData[9]}</Typography>
                                                  </TableCell>
                                                  <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", color: "#1A73E8" }}>
                                          <Typography className={classes.tableData}>{row.resultData[10]}</Typography>
                                                  </TableCell>
                                              </TableRow>
                                          </TableCell>
                                          </TableRow>
                                  )
 })}
                                  </TableBody>
                              </Table>

                  </Grid>
                  </Grid>
                  : <Grid container spacing={2} style={{ width: "1360px", display: "flex", height: "600px", overflowY: 'overlay', paddingBottom: "20px", paddingTop: "12px" }}>
                    <Grid item xs={12} md={8} lg={8} style={{ display: "flex", flexDirection: "column", marginBottom: "435px" }}>

                  {inputToDisplay.map((row) => (
                  <div>
                      <Grid container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        <Grid item lg={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div style={{ alignSelf: "center" }}>
                                {/* <InfoIcon style={{ color: "#FB8C00" }} fontSize="small"/>
                                <FlagOutlinedIcon style={{ color: "#9AA0A6" }} fontSize="small"/> */}
                                <Typography>
                                    Sheet {row.sheetNo}
                                </Typography>
                                <Typography>
                                    Sr. No. : {row.position}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg ={11} style={{ display: "flex", flexDirection: "column", height: "110px" }}>
                            <DraggableStrip pos={pos} setPos={setPos} image={row.stripImagePath}></DraggableStrip>
                            <div style={{ paddingLeft: "50px", display: "flex", zIndex: "2" }} id = {row.position}>
                          {row.bandData.map((j, index) => {
                              if (index <= 26) {
                              return (
                                // , paddingRight: index === 2 || index === 15 || index === 19 ? "30px" : "0px"
                             <Grid container alignItems="center" display="flex" direction="column" style={{ width: "23px", paddingRight: "1px" }}>
                                    <Grid item>
                                    { j.confidence === false
                                    ? <Divider orientation="vertical" style={{ marginTop: "-30px", marginBottom: "-4px", height: "34px", background: "#F57C00", width: "2px" }}/>
                                    : ''}
                                    </Grid>
                                    <Grid item>
                                        <Checkbox
                                            className={classes.root}
                                            disableRipple
                                            checked = {j.bandPresent}
                                            icon={<span className={j.confidence === true ? classes.icon : classes.iconOrangeBorder} />}
                                            checkedIcon={<span className={j.confidence === true ? classes.checkedIcon : classes.checkedIconOrangeBorder} />}
                                            onChange={handleBandChange(row.sheetId, row.rowId, index)}
                                        />
                                    </Grid>
                              </Grid>
                          )
 }
 return true
})}
                          </div>

                          </Grid>
                      </Grid>
                  <Divider />
                  </div>
                  ))}
              </Grid>

                  <Grid item xs={12} md={4} lg={4} style={{ display: "flex", marginBottom: "435px" }}>

                  <Table className={classes.table} size="small" style={{ width: "420px", border: "1px solid #1A73E8", borderRadius: "0" }} padding="none" aria-label="simple table">
                           <TableBody>
                           {outputToDisplay.map((row, i) => (
                               <TableRow key={i}>
                                   {row.resultData[0] === 'MTB NOT DETECTED'
                                    ? <TableCell colSpan={11} align="center" style={{ height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                    <Typography className={classes.mtbNotDetectedText}>{row.resultData[0]}</Typography>
                                    </TableCell>
                                    : row.resultData[0] === 'INVALID TEST' || row.resultData[0] === 'INDETERMINATE TEST'
                                    ? <TableCell colSpan={11} align="center" style={{ height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                    <Typography className={classes.invalidTestText}>{row.resultData[0]}</Typography>
                                    </TableCell>
                                    : <React.Fragment>
                                    <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[0]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[1]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[2]}</Typography>

                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[3]}</Typography>

                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[4]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[5]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[6]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[7]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[8]}</Typography>
                                   </TableCell>
                                   <TableCell align="center" style={{ minWidth: "150px", height: "150px", borderRight: "1px solid #1A73E8", borderBottom: "1px solid #1A73E8" }}>
                                       <TableRow>
                                           <TableCell align="center" style={{ width: "30px", maxWidth: "30px", height: "150px", borderRight: "1px solid #1A73E8", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[9]}</Typography>

                                           </TableCell>
                                           <TableCell align="center" style={{ width: "30px", maxWidth: "30px", color: "#1A73E8", borderRight: "1px solid #1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[10]}</Typography>

                                           </TableCell>
                                           <TableCell align="center" style={{ width: "30px", maxWidth: "30px", color: "#1A73E8", borderRight: "1px solid #1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[11]}</Typography>

                                           </TableCell>
                                           <TableCell align="center" style={{ width: "30px", maxWidth: "30px", color: "#1A73E8", borderRight: "1px solid #1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[12]}</Typography>

                                           </TableCell>
                                           <TableCell align="center" style={{ width: "30px", maxWidth: "30px", color: "#1A73E8" }}>
                                   <Typography className={classes.tableData}>{row.resultData[13]}</Typography>

                                           </TableCell>
                                       </TableRow>
                                   </TableCell>

                                   </React.Fragment>}

                               </TableRow>
                           ))}
                           </TableBody>
                       </Table>

           </Grid>

                  </Grid>}

        </React.Fragment>
    );
}

export default InterpretationTable;
