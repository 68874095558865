import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  status: {
    danger: "orange",
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 50,
        textTransform: "none",
      },
    },
    MuiTab: {
        root: {
          textTransform: "none",
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: '0',
      }
    }
}
});

export default theme;
