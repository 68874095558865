import React, { useEffect, useState } from 'react';
import { createBatch, getBatchInfoForDashboard, getUserInfo } from "../actions/global"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'

import AppBar from "../components/appBar"
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Grid from '@material-ui/core/Grid';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { logUserDetails } from '../analytics/analyticsHelper'
import moment from 'moment'

// import { useHistory } from 'react-router-dom';

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#DADCE0"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#DADCE0",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DADCE0"
      },
      "&:hover fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px"
      }
    }
  }
})(TextField);

const useStyles = makeStyles((theme) => ({
    leftPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 360,
      },
    paper: {
        padding: theme.spacing(2),
        elevation: "0",
        display: 'flex',
        flexDirection: 'column',
        background: "#F8F9FA",

      },
      container: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      dashboardContainer: {
        padding: 20,
      },
      nextStepButton: {
          display: "flex",
          paddingTop: 300,
          flexDirection: "column",
          alignItems: "center"
      },
      rightSideDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 280
    },
    c1: {
        paddingTop: 5
    },
    heading: {
        display: "flex",
        justifyContent: "flex-start",
    },
    button: {
      margin: theme.spacing(1),
      color: theme.palette.getContrastText("#1967D2"),
      backgroundColor: "#1967D2",
      "&:hover": {
          backgroundColor: "#1967D2",
          "@media (hover: none)": {
            backgroundColor: "#1967D2"
          }
        }
  },
   textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 180,
  },
  interpretSheetsGuideText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "5px",
    fontSize: "12px",
    fontWeight: "400",
  },
  interpretGuideGrid: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: "64px"
  }
}));

export default function Album ({ userId, setState }) {
  const classes = useStyles();
  // const history = useHistory();
  const dispatch = useDispatch();
  const [batchType, setBatchType] = React.useState(0);
  const [batchTypeFilter, setBatchTypeFilter] = React.useState(2);
  const [dateTypeFilter, setDateTypeFilter] = React.useState(null);
  const [batchData, setBatchData] = React.useState([])
  const [userInfo, setUserInfo] = React.useState(null);
  const [filteredBatches, setFilteredBatches] = useState([])
  const dashboardRows = useSelector(state => state.globalActionsReducer.batch_info_dashboard)?.batches
  const userInformation = useSelector(state => state.globalActionsReducer.user_info)
  React.useEffect(() => {
    dispatch(getUserInfo(userId))
    dispatch(getBatchInfoForDashboard(userId))
    logUserDetails(userId)
  }, [dispatch, userId])

  React.useEffect(() => {
    setBatchData(dashboardRows)
  }, [dashboardRows])

  useEffect(() => {
    if (dashboardRows === undefined) return
    let filtered = dashboardRows?.filter(batch => (batchTypeFilter === 2 ? true : batch.batch_type === (batchTypeFilter === 0 ? 'FL' : 'SL')))
    if (dateTypeFilter) {
      filtered = filtered.filter(batch => (batch.updated_at === dateTypeFilter))
    }
    setFilteredBatches(filtered)
  }, [batchTypeFilter, dateTypeFilter, dashboardRows])

  React.useEffect(() => {
    setUserInfo(userInformation)
    window.sessionStorage.setItem('name', userInformation?.name);
    window.sessionStorage.setItem('lab_name', userInformation?.lab_name);
    window.sessionStorage.setItem('designation', userInformation?.designation);
    window.sessionStorage.setItem('user_id', userId);
  }, [userId, userInformation])

  function startLPABatch () {
    dispatch(createBatch(batchType === 0 ? 'FL' : 'SL', userId))
            .then(() => {
              setState("u")
              // history.push('/upload')
            })
  }
const handleDropDownChange = (event) => {
    setBatchType(event.target.value);
  };

  const handleDropDownChangeFilter = (event) => {
    setBatchTypeFilter(event.target.value);
  };
  const handleDateChange = (event) => {
    setDateTypeFilter(event.target.value);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar userInfo = {userInfo}/>
      <Grid container className={classes.dashboardContainer} style={{ background: "white" }}>
      <Typography style={{ fontSize: "28px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px", textAlign: "left", paddingLeft: "20px" }} >
                    Dashboard
                </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.container} style={{ background: "white" }}>
            {/* left side */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={classes.leftPaper} elevation={0} style={{ background: "#F8F9FA" }} >
                <Grid container style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                  <Grid container style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Grid item>
                      <Typography style={{
                        color: "#202124",
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        letterSpacing: "0.1px"
                      }}>
                        Interpret LPA sheets
                      </Typography>
                    </Grid>
                    <Grid item style={{ paddingTop: "20px" }}>
                      <Typography style={{
                        color: "#3C4043",
                        fontSize: "14px",
                        fontWeight: "normal",
                        lineHeight: "22px",
                        letterSpacing: "0.1px",
                        textAlign: "center"
                      }}>
                        Upload scanned images of LPA sheets to interpret.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginTop: "52px", marginBottom: "24px" }}>
                    <Grid container style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                      {/* Maybe Keep items in list and then iterate the list to create components. Will reduce line of codes */}
                      <Grid item className={classes.interpretGuideGrid}>
                        <img src={require("../constants/cloudUploadIcon.svg")} alt="Upload Icon"/>
                        <div className={classes.interpretSheetsGuideText}>
                          Upload images
                        </div>
                      </Grid>
                      <Grid item style={{ marginLeft: "4px", marginRight: "4px", marginTop: "-37px" }}>
                        <img src={require("../constants/rightArrowIcon.svg")} alt="Right Arrow"/>
                      </Grid>
                      <Grid item className={classes.interpretGuideGrid}>
                        <img src={require("../constants/interpretationIcon.svg")} alt="Interpretation Icon"/>
                        <div className={classes.interpretSheetsGuideText}>
                          AI gives interpretation
                        </div>
                      </Grid>
                      <Grid item style={{ marginLeft: "4px", marginRight: "4px", marginTop: "-37px" }}>
                        <img src={require("../constants/rightArrowIcon.svg")} alt="Right Arrow"/>
                      </Grid>
                      <Grid item className={classes.interpretGuideGrid}>
                        <img src={require("../constants/reviewIcon.svg")} alt="Review Icon"/>
                        <div className={classes.interpretSheetsGuideText}>
                          Review interpretation
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <div style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex"
                  }}>
                    <CssTextField
                      select
                      size="small"
                      variant="outlined"
                      value={batchType}
                      // defaultValue={1}
                      onChange={handleDropDownChange}
                      style={{ width: "120px", margin: 8 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DescriptionOutlinedIcon style={{ color: '#5F6368' }}/>
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem key={0} value={0}>
                        FL
                      </MenuItem>
                      <MenuItem key={1} value={1}>SL</MenuItem>
                    </CssTextField>
                    <Button className={classes.button} onClick={startLPABatch} variant="contained">
                      Start LPA interpretation
                    </Button>
                  </div>

                </Grid>
              </Paper>
            </Grid>
            {/* right side */}
            <Grid item xs={12} md={8} lg={8}>

              <Paper elevation={0} className={classes.paper}>
              <Grid container spacing={2} style={{ paddingLeft: "30px", paddingRight: "30px" }}>
              <Grid item style={{ display: "flex", paddingBottom: "20px", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
                <Typography style={{ paddingTop: "10px", width: "200px", fontSize: "18px", fontStyle: "normal", fontWeight: "500", lineHeight: "24px", textAlign: "left" }} >
                  All Past Interpretations
                </Typography>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "right", alignItems: "end" }}>
                  <CssTextField
                    id="date"
                    type="date"
                    size="small"
                    variant="outlined"
                    value={dateTypeFilter}
                    onChange={handleDateChange}
                    className={classes.textField}
                    style={{ width: "208px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps= {{
                      max: (new Date()).toISOString().split('T')[0]
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={() => setDateTypeFilter("")} style={{ cursor: "pointer" }}>
                          <HighlightOffIcon style={{ color: '#5F6368' }}/>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CssTextField
                    select
                    size="small"
                    variant="outlined"
                    value={batchTypeFilter}
                    // defaultValue={1}
                    label={"Test Type"}
                    onChange={handleDropDownChangeFilter}
                    placeholder="LPA Type"
                    style={{ width: "160px", marginLeft: "20px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DescriptionOutlinedIcon style={{ color: '#5F6368' }}/>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={0} value={0}>FL</MenuItem>
                    <MenuItem key={1} value={1}>SL</MenuItem>
                    <MenuItem key={2} value={2}>All</MenuItem>
                  </CssTextField>
                </div>
              </Grid>

              <Grid item style={{ width: "100%" }}>
               <Grid container spacing={2} style={{ height: "600px", overflow: "auto" }}>
                {batchData && filteredBatches.map((row, i) => (
                    <Grid item key={i} xs={12} sm={6} md={3}>
                      <Paper elevation={0} style={{ border: "1px solid #DADCE0", display: "flex", flexDirection: "column", alignItems: "center", height: "340px", width: "200px" }}>
                        <div style={{ height: "250px", width: "200px" }}>
                        <img alt="Uploaded file" src={row.thumbnail_image_location} style={{ width: "100%", height: "100%", padding: "10px" }} />
                        </div>
                      <Typography style={{ paddingBottom: "5px", fontSize: "14px", fontWeight: "500", lineHeight: "22px", letterSpacing: "0.1px", }}>  {moment(row.updated_at).format('ddd DD MMM YYYY')} </Typography>
                      <Typography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "18px", letterSpacing: "0.1px", }}>{row.number_of_sheets} {row.batch_type} LPA sheets</Typography>
                      <Typography style={{ paddingBottom: "10px", fontSize: "14px", fontWeight: "400", lineHeight: "18px", letterSpacing: "0.1px", }}>{row.number_of_samples} samples</Typography>
                      </Paper>
                    </Grid>

                ))}
                </Grid>
              </Grid>

              </Grid>

              </Paper>
            </Grid>
          </Grid>

    </React.Fragment>
  );
}
