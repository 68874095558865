import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getInterpretationData, getBatchValidity } from "../actions/global"

import AppBar from "../components/appBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
// import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from '@material-ui/core/Paper';
// import PrintIcon from '@material-ui/icons/Print';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as YourSvg } from '../constants/summarysampleicon.svg'
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

const Accordion = withStyles({
    root: {
      borderTop: "1px solid #BDC1C6",
      borderBottom: "1px solid #BDC1C6",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0
      },
      "&:before": {
        display: "none"
      },
      "&$expanded": {
        marginBottom: "0px",
        marginTop: "0px"
      }
    },
    "&$expanded": {
        marginBottom: "0px",
        marginTop: "0px"
    }
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: "yellow",
      // borderBottom: "1px solid yellow",
      marginBottom: -1,
      minHeight: 40,
      paddingLeft: "0px",
      paddingRight: "0px",
      "&$expanded": {
        minHeight: 40
      }
    },
    content: {
      "&$expanded": {
        margin: "0 0"
      }
    },
    "&$expanded": {
        marginTop: "0px",
        marginBottom: "0px"
      }
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      backgroundColor: "#F8F9FA",
      maxHeight: "120px",
      overflow: "auto",
      padding: "0px"
    }
  }))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    heading: {
        display: "flex",
        justifyContent: "flex-start",
    },
    button1: {
      margin: theme.spacing(1),
      color: theme.palette.getContrastText("#1967D2"),
      backgroundColor: "#1967D2",
      "&:hover": {
          backgroundColor: "#1967D2",
          "@media (hover: none)": {
            backgroundColor: "#1967D2"
          }
      }
    },
    printButton: {
        width: "120px",
        height: "32px",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        color: "#5F6368",
        fontSize: "12px",
        borderRadius: 50,
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#1967D2",
          "@media (hover: none)": {
            backgroundColor: "#1967D2"
          }
        }
    },
    accordianSummaryText: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
      letterSpacing: "0.1px"
    },
    accordianSummaryTextHeading: {
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "22px",
      letterSpacing: "0.1px",
      paddingRight: "4px"
    }

}));

export default function Summary ({ setState }) {
  const classes = useStyles();
  // const history = useHistory();
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState("");
  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
  const summaryData = useSelector(state => state.globalActionsReducer.summary)
  const batchType = summaryData?.batch_type ? summaryData?.batch_type : 'FL'
  // console.log("summary", summaryData)
  if (!batchId) {
    setState("d")
    // history.push("dashboard")
  }

  function getAccordianDetails (arr) {
    return (<List>
              {arr?.map((i) => (
                      <ListItem>
                      <YourSvg/>
                      <Typography style={{ paddingLeft: "10px", color: "#3C4043", fontSize: "14px", fontWeight: "500", lineHeight: "22px", letterSpacing: "0.1px" }}>
                      Sample ID:  {i}
                                  </Typography>
                      </ListItem>

              ))}
            </List>)
  }
  function displayResultsForFl () {
    const actualSummary = summaryData.summary;
    return (
      <React.Fragment>
     { actualSummary?.rif_and_inh_sensitive &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
        {expanded === "panel1" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
        <Typography className={classes.accordianSummaryTextHeading}>
          M.tb detected:
          </Typography>
          <Typography className={classes.accordianSummaryText}>
          Rif and Inh Sensitive  ({actualSummary?.rif_and_inh_sensitive?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
        {getAccordianDetails(actualSummary?.rif_and_inh_sensitive)}
        </AccordionDetails>
      </Accordion>}
      {
        actualSummary?.send_for_sl_lpa &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel2"}
        onChange={handleAccordionChange("panel2")}
        style={{ expanded: { margin: "0px" } }}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
          {expanded === "panel2" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
          <Typography className={classes.accordianSummaryTextHeading}>
          M.tb detected:
          </Typography>
          <Typography className={classes.accordianSummaryText}>
          Send for SL-LPA ({actualSummary?.send_for_sl_lpa?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
          {getAccordianDetails(actualSummary?.send_for_sl_lpa)}
        </AccordionDetails>
      </Accordion>
  }
  {
    actualSummary?.invalid_repeat_fl_lpa &&
      <Accordion
      square
      elevation={0}
      expanded={expanded === "panel3"}
      onChange={handleAccordionChange("panel3")}
    >
      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
      {expanded === "panel3" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
      <Typography className={classes.accordianSummaryTextHeading}>
      Invalid:
          </Typography>
        <Typography className={classes.accordianSummaryText} >
         Repeat FL-LPA ({actualSummary?.invalid_repeat_fl_lpa?.length} samples)
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "0px" }}>
      {getAccordianDetails(actualSummary?.invalid_repeat_fl_lpa)}
      </AccordionDetails>
    </Accordion>
  }
  {
    actualSummary?.indeterminate_repeat_fl_lpa &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel4"}
        onChange={handleAccordionChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
        {expanded === "panel4" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
        <Typography className={classes.accordianSummaryTextHeading}>
        Indeterminate:
          </Typography>
          <Typography className={classes.accordianSummaryText}>
          Repeat FL-LPA ({actualSummary?.indeterminate_repeat_fl_lpa?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
        {getAccordianDetails(actualSummary?.indeterminate_repeat_fl_lpa)}
        </AccordionDetails>
      </Accordion>}
{
  actualSummary?.send_for_culture &&
  <Accordion
        square
        elevation={0}
        expanded={expanded === "panel5"}
        onChange={handleAccordionChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
        {expanded === "panel5" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
        <Typography className={classes.accordianSummaryTextHeading}>
        M.tb not detected:
          </Typography>
          <Typography className={classes.accordianSummaryText}
          >
            Send for Culture ({actualSummary?.send_for_culture?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
        {getAccordianDetails(actualSummary?.send_for_culture)}
        </AccordionDetails>
      </Accordion>
}

{
  actualSummary?.invalid_control_bands &&
  <Accordion
        square
        elevation={0}
        expanded={expanded === "panel6"}
        onChange={handleAccordionChange("panel6")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
        {expanded === "panel6" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
        <Typography className={classes.accordianSummaryTextHeading}>
        Invalid Control bands:
          </Typography>
          <Typography className={classes.accordianSummaryText}
          >
             Repeat FL-LPA ({actualSummary?.invalid_control_bands?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
        {getAccordianDetails(actualSummary?.invalid_control_bands)}
        </AccordionDetails>
      </Accordion>
}

    </React.Fragment>
    )
  }

  function displayResultsForSl () {
    const actualSummary = summaryData.summary;
    return (
      <React.Fragment>
        {
  actualSummary?.all_sensitive &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
        {expanded === "panel1" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
        <Typography className={classes.accordianSummaryTextHeading} >
            MTB Detected:
          </Typography>
          <Typography className={classes.accordianSummaryText}>
            All Sensitive  ({actualSummary?.all_sensitive?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
        {getAccordianDetails(actualSummary?.all_sensitive)}
        </AccordionDetails>
      </Accordion>
  }
   {
  actualSummary?.flq_sensitive_sli_resistant &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel2"}
        onChange={handleAccordionChange("panel2")}
        style={{ expanded: { margin: "0px" } }}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
          {expanded === "panel2" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
          <Typography className={classes.accordianSummaryTextHeading} >
            MTB Detected:
          </Typography>
          <Typography className={classes.accordianSummaryText}>
            FLQ sensitive, SLI resistant ({actualSummary?.flq_sensitive_sli_resistant?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
          {getAccordianDetails(actualSummary?.flq_sensitive_sli_resistant)}
        </AccordionDetails>
      </Accordion>
  }
     {
  actualSummary?.flq_resistant_sli_sensitive &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel3"}
        onChange={handleAccordionChange("panel3")}
        style={{ expanded: { margin: "0px" } }}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
          {expanded === "panel3" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
          <Typography className={classes.accordianSummaryTextHeading} >
            MTB Detected:
          </Typography>
          <Typography className={classes.accordianSummaryText}>
          FLQ resistant, SLI sensitive ({actualSummary?.flq_resistant_sli_sensitive?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
          {getAccordianDetails(actualSummary?.flq_resistant_sli_sensitive)}
        </AccordionDetails>
      </Accordion>
  }
     {
  actualSummary?.flq_resistant_sli_resistant &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel4"}
        onChange={handleAccordionChange("panel4")}
        style={{ expanded: { margin: "0px" } }}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
          {expanded === "panel4" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
          <Typography className={classes.accordianSummaryTextHeading} >
            MTB Detected:
          </Typography>
          <Typography className={classes.accordianSummaryText}>
          FLQ resistant, SLI resistant ({actualSummary?.flq_resistant_sli_resistant?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
          {getAccordianDetails(actualSummary?.flq_resistant_sli_resistant)}
        </AccordionDetails>
      </Accordion>
  }
   {
  actualSummary?.invalid_repeat_sl_lpa &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel5"}
        onChange={handleAccordionChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
        {expanded === "panel5" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
        <Typography className={classes.accordianSummaryTextHeading} >
        Invalid:
          </Typography>
          <Typography className={classes.accordianSummaryText} >
             Repeat SL-LPA ({actualSummary?.invalid_repeat_sl_lpa?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
        {getAccordianDetails(actualSummary?.invalid_repeat_sl_lpa)}
        </AccordionDetails>
      </Accordion>
  }
     {
  actualSummary?.indeterminate_repeat_sl_lpa &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel6"}
        onChange={handleAccordionChange("panel6")}
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
        {expanded === "panel6" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
        <Typography className={classes.accordianSummaryTextHeading} >
        Indeterminate:
          </Typography>
          <Typography className={classes.accordianSummaryText}>
             Repeat SL-LPA ({actualSummary?.indeterminate_repeat_sl_lpa?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
        {getAccordianDetails(actualSummary?.indeterminate_repeat_sl_lpa)}
        </AccordionDetails>
      </Accordion>
  }
       {
  actualSummary?.send_for_culture &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel7"}
        onChange={handleAccordionChange("panel7")}
      >
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
        {expanded === "panel7" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
        <Typography className={classes.accordianSummaryTextHeading} >
        M.tb not detected:
          </Typography>
          <Typography className={classes.accordianSummaryText}
          >
            Send for Culture ({actualSummary?.send_for_culture?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
        {getAccordianDetails(actualSummary?.send_for_culture)}
        </AccordionDetails>
      </Accordion>
  }

{
  actualSummary?.invalid_control_bands &&
      <Accordion
        square
        elevation={0}
        expanded={expanded === "panel8"}
        onChange={handleAccordionChange("panel8")}
      >
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" style={{ backgroundColor: "#F8F9FA", paddingLeft: "0px", paddingRight: "0px" }}>
        {expanded === "panel8" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
        <Typography className={classes.accordianSummaryTextHeading} >
        Invalid Control bands:
          </Typography>
          <Typography className={classes.accordianSummaryText}
          >
            Repeat SL-LPA ({actualSummary?.invalid_control_bands?.length} samples)
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
        {getAccordianDetails(actualSummary?.invalid_control_bands)}
        </AccordionDetails>
      </Accordion>
  }
    </React.Fragment>
    )
  }

  function handleBack () {
    if (summaryData?.is_valid_batch){
      dispatch(getInterpretationData(batchId)).then(() => {
        setState("i") })
    }
    else{
      dispatch(getBatchValidity(batchId)).then(() => { 
        setState('b') })
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar />
      <Grid container justify="center" alignItems="center" direction="row" style={{ backgroundColor: "white", height: "88vh" }}>
          <Grid item lg={4}>
              <Paper elevation={0} style={{ background: "#F8F9FA", height: "680px", width: "562px", borderRadius: "6px", padding: "30px" }}>
                <div className={classes.heading}>
                    {/* <ArrowBackIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => history.push("/interpretation")}/> */}
                    <ArrowBackIcon onClick={handleBack} style={{ color: "#1A73E8", cursor: "pointer"}}></ArrowBackIcon>
                    <Typography style={{ fontSize: "28px", fontWeight: "bold", lineHeight: "24px", letterSpacing: "0.1px", alignItems: "center", paddingLeft: "15px" }} gutterBottom>
                    Interpretation Summary
                    </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center", paddingTop: "30px", justifyContent: "space-between" }}>
                    <Typography style={{ fontSize: "18px", fontWeight: "700", lineHeight: "24px", letterSpacing: "0.1px", color: "#202124" }}>
                    Workflow recommendations
                    </Typography>
                    <div>
                    {/* <Button startIcon={<PrintIcon/>} style={{
marginRight: "8px",
width: "120px",
height: "32px",
backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                                color: "#5F6368",
                                                                fontSize: "12px",
                                                                borderRadius: "16px",
                                                                textTransform: "none",
                                                                "&:hover": {
                                                                    backgroundColor: "#1967D2",
                                                                    "@media (hover: none)": {
                                                                        backgroundColor: "#1967D2"
                                                                    }
                                                                    }
}}>
                        Print Report
                    </Button>
                    <Button startIcon={<GetAppIcon/>} style={{
width: "120px",
 height: "32px",
 backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                                color: "#5F6368",
                                                                fontSize: "12px",
                                                                borderRadius: "16px",
                                                                textTransform: "none",
                                                                "&:hover": {
                                                                    backgroundColor: "#1967D2",
                                                                    "@media (hover: none)": {
                                                                        backgroundColor: "#1967D2"
                                                                    }
                                                                    }
}}>
                        Download
                    </Button> */}
        </div>
                </div>
                    <Typography style={{ fontSize: "14px", fontWeight: "500", lineHeight: "22px", letterSpacing: "0.1px", color: summaryData?.is_valid_batch ? 'green' : 'red' }}>{summaryData?.is_valid_batch ? 'Valid' : 'Invalid'} Batch</Typography>

                    <div style={{ overflow: "auto", height: "480px", paddingTop: "20px" }}>
                      {batchType === 'FL' ? displayResultsForFl() : displayResultsForSl()}
     </div>

                    <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                    <Button onClick={() => setState("d")} style={{
                                width: "250px",
                                height: "40px",
                                color: "#FFFFFF",
                                fontSize: "16px",
                                backgroundColor: "#1967D2",
                                borderRadius: 50,
                                "&:hover": {
                                    backgroundColor: "#1967D2",
                                    "@media (hover: none)": {
                                        backgroundColor: "#1967D2"
                                    }
                                    }
                            }}>Submit Result</Button>
                      {/* <Button onClick={handleBack} className={classes.button}
                        variant="contained" endIcon={<ArrowBackIcon></ArrowBackIcon>}>
                        Previous
                      </Button> */}
                    </div>
              </Paper>
          </Grid>
      </Grid>

    </React.Fragment>
  );
}
