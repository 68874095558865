import { Paper } from "@material-ui/core";
import React, { useState } from "react";

import { IndicatorComponent } from "./IndicatorComponent";
import { LowConfidenceTipsComponent } from "./LowConfidenceTipsComponent";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { ReviewingStripsTipsComponent } from "./ReviewingStripsTipsComponent";
import { AligningStripsTipsComponent } from "./AligningStripsTipsComponent";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: "#1967D2",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#1967D2",
      color: "#FFFFFF",
      "@media (hover: none)": {
        backgroundColor: "#1967D2"
      }
    }
  },
}));

export const InterpretationTipsComponent = () => {
  const classes = useStyles()
  const [showTip, setShowTip] = useState(true);
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0)
  const totalSlideNumber = 3
  const handleNextClick = () => {
    if (currentSlideNumber === totalSlideNumber - 1) {
      setShowTip(false)
    } else {
      setCurrentSlideNumber(prevSlideNumber => (prevSlideNumber + 1) % totalSlideNumber)
    }
  }
  return (
    showTip && (
      <Paper
        elevation={4}
        style={{
          overflow: "auto",
          height: "auto",
          width: "780px",
          background: "#034CAC",
          position: "fixed",
          marginBottom: "30px",
          marginRight: "30px",
          marginLeft: "30px",
          paddingBottom: "20px",
          bottom: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        { currentSlideNumber === 0 && <LowConfidenceTipsComponent setShowTip={setShowTip}/> }
        { currentSlideNumber === 1 && <ReviewingStripsTipsComponent setShowTip={setShowTip} handleBackButton={() => setCurrentSlideNumber(prevSlideNumber => (prevSlideNumber - 1 + totalSlideNumber) % totalSlideNumber)} /> }
        { currentSlideNumber === 2 && <AligningStripsTipsComponent setShowTip={setShowTip} handleBackButton={() => setCurrentSlideNumber(prevSlideNumber => (prevSlideNumber - 1 + totalSlideNumber) % totalSlideNumber)} /> }
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
          <Button className={classes.button} variant="contained" component="label" style={{ width: "128px" }} onClick = { handleNextClick }>
            { currentSlideNumber === (totalSlideNumber - 1) ? 'Okay' : 'Next' }
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IndicatorComponent currentSlideNumber={currentSlideNumber} totalSlideNumber={totalSlideNumber}/>
        </div>
      </Paper>
    )
  );
};
