import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

export default function MenuAppBar ({ userInfo }) {
  const userInformation = userInfo || {
    name: window.sessionStorage.getItem('name'),
    designation: window.sessionStorage.getItem('designation'),
    lab_name: window.sessionStorage.getItem('lab_name')
  };
  return (
    <React.Fragment>
      <AppBar position="relative" elevation={0}
              style={{ background: "#E8F0FE", height: "99px", display: "flex", justifyContent: "center" }}>
        <Toolbar>
          <Typography
            style={{ color: "#202124", fontSize: "45px", fontWeight: "900", lineHeight: "38px", letterSpacing: "-1px" }}>
            LIMS
          </Typography>
          <Grid style={{
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-end",
            justifyContent: "center",
            flex: 1
          }}>
            <Typography style={{
              color: "#202124",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "18px",
              letterSpacing: "0.1px"
            }}>
              {userInformation.name}
            </Typography>
            <Typography style={{
              color: "#202124",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "18px",
              letterSpacing: "0.1px"
            }}>
              {userInformation.lab_name}
            </Typography>
            <Typography style={{
              color: "#202124",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "18px",
              letterSpacing: "0.1px"
            }}>
              {userInformation.designation}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
