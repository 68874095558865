/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { deleteSheet, getBatchRoi, getQualityCheck, submitSheet, getSheetsInBatch } from "../actions/global"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'

import {
  logBadImageQualityEvent, logPreviewSheetEvent,
  logSheetUploadedEvent
} from "../analytics/analyticsHelper";
import AppBar from "../components/appBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import ErrorDialog from '../components/errorDialog'
import Grid from '@material-ui/core/Grid';
import ImageCarousel from '../components/carousel'
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { PageTitle } from "../constants/app-contants";
import Paper from '@material-ui/core/Paper';
import PublishIcon from '@material-ui/icons/Publish';
import ReportDialog from "../components/report"
import Snackbar from '@material-ui/core/Snackbar';
import { TipsSheetComponent } from "../components/tips/TipsSheetComponent";
import Typography from '@material-ui/core/Typography';
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { ScreenNames } from "../constants/CustomEnums";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// import { useHistory } from 'react-router-dom';

const BorderLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: '#1967D2',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 630,
  },
  batchImageContainer: {
    height: 500,
    overflow: 'auto',
    paddingLeft: "30px",
    paddingRight: "30px"
  },
  container: {
    padding: 40,
  },
  nextStepButton: {
    justifyContent: "center",
    display: "flex",
    paddingTop: "50px"
  },
  rightSideDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 150
  },
  c1: {
    paddingTop: 5,
    paddingBottom: 10,
    alignItems: "center"
  },
  heading: {
    display: "flex",
    justifyContent: "flex-start",
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText("#1967D2"),
    backgroundColor: "#1967D2",
    "&:hover": {
      backgroundColor: "#1967D2",
      "@media (hover: none)": {
        backgroundColor: "#1967D2"
      }
    }
  },
  reportButton: {
    color: "#1967D2",
    "&:hover": {
      backgroundColor: alpha("#1967D2", theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent"
      }
    }
  },
  nextButton: {
    margin: theme.spacing(2),
    color: theme.palette.getContrastText("#BDC1C6"),
    backgroundColor: "#BDC1C6",
    "&:hover": {
      backgroundColor: "#BDC1C6",
      "@media (hover: none)": {
        backgroundColor: "#BDC1C6"
      }
    }
  },
  dialogContent: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.1px",
    color: "#3C4043"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

const Upload = ({ setState }) => {
  const classes = useStyles();
  // const history = useHistory()
  const [reupload, setReupload] = useState(1); // for reupload of the image
  const [qcLoading, setQcLoading] = useState(false); // for the QC API call
  const [submitLoading, setSubmitLoading] = useState(false); // for the Submit API call
  const [deleteSheetId, setDeleteSheetId] = React.useState(-1);
  const [currentFile, setCurrentFile] = React.useState({});
  const filesInBatch = useSelector(state => state.globalActionsReducer.uploaded_sheets)?.sheets;
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  // if (filesInBatch?.length && uploadedFiles.length === 0)
  // {
  //   setUploadedFiles(filesInBatch);
  // }
  
  const [reportDialog, setReportDialog] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState("")
  const [erroDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [errorHeading, setErrorHeading] = React.useState("");
  const [errorContent, setErrorContent] = React.useState("");
  const [imagePreview, setImagePreview] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const dispatch = useDispatch();
  const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
  if (!batchId) {
    setState("d")
    // history.push("dashboard/" + window.sessionStorage.getItem('user_id'))
  }
  const handleErrorDialogOpen = () => {
    setErrorDialogOpen(true);
  };
  const qualityCheckPassed = useSelector(state => state.globalActionsReducer.quality_check_passed);
  const fileQualityPayload = useSelector(state => state.globalActionsReducer.quality_check_payload);
  const fileQualityPayloadError = useSelector(state => state.globalActionsReducer.quality_check_payload_error);

  useEffect(() => {
    if (fileQualityPayload?.sheet_quality === false) {
      // Log wrong image
      logBadImageQualityEvent()
    }
    setCurrentFile({
      sheet_id: fileQualityPayload?.sheet_id,
      sheet_type: fileQualityPayload?.sheet_type,
      sheet_quality: fileQualityPayload?.sheet_quality,
      error_message: fileQualityPayload?.error_message,
      transformed_image_location: fileQualityPayload?.transformed_image_location
    })
  }, [dispatch, fileQualityPayload])

  useEffect(() => {
    if (qualityCheckPassed === false) {
      if (fileQualityPayloadError?.msg_code === "duplicate_sheet") { // Duplicate sheet
        logSheetUploadedEvent(true)
        setErrorHeading("You have already uploaded this sheet");
        setErrorContent("You can not upload the same sheet twice.");
        handleErrorDialogOpen();
        setReupload(1);
      } else { // same batch
        logSheetUploadedEvent(false)
        setErrorHeading("Server error");
        setErrorContent("Please reload the page and try again.");
        handleErrorDialogOpen();
        setReupload(1)
      }
    } else if (qualityCheckPassed === true) {
      logSheetUploadedEvent(false)
    }
  }, [dispatch, fileQualityPayload])

  useEffect(() => {
      setUploadedFiles(filesInBatch ? filesInBatch : []);
  }, [filesInBatch])

  const handleErrorDialogClose = (value) => {
    setErrorDialogOpen(false);
  };

  const [selectedImageIndexForPreview, setSelectedImageIndexForPreview] = useState(0)
  const handleImagePreviewOpen = (index) => {
    logPreviewSheetEvent(ScreenNames.UPLOAD)
    setImagePreview(true);
    setSelectedImageIndexForPreview(index)
  };

  const handleImagePreviewClose = (value) => {
    setImagePreview(false);
  };

  async function handleUploadClick (event) {
    setQcLoading(true)
    setUploadedImage(URL.createObjectURL(event.target.files[0]))
    setReupload(0)
    await dispatch(getQualityCheck(batchId, event.target.files[0])).then(() => {
      setQcLoading(false)
    })
  };

  function handleSubmit () {
    dispatch(submitSheet(currentFile.sheet_id))
    uploadedFiles.push(currentFile);
    setUploadedFiles(uploadedFiles);
    setReupload(1);
    setCurrentFile({})
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  function handleClose () {
    setDeleteSheetId(-1);
  };

  function handleDelete () {
    dispatch(deleteSheet({ sheet_id: deleteSheetId })).then(() => dispatch(getSheetsInBatch(batchId)))
    var newFiles = uploadedFiles.filter(item => item.sheet_id !== deleteSheetId)
    setUploadedFiles(newFiles);
    setDeleteSheetId(-1);
  };

  function handleNext () {
    setSubmitLoading(true)
    dispatch(getBatchRoi(batchId)).then(() => {
      setSubmitLoading(false);
      setState("m")
    })
  }

  function handleBack () {
      setState("d");
  }
  return (
    <React.Fragment>
      <CssBaseline/>
      <AppBar />
      {imagePreview && <ImageCarousel
        open={imagePreview}
        onClose={handleImagePreviewClose}
        images={uploadedFiles.map(row => row.transformed_image_location)}
        index = {selectedImageIndexForPreview}
      />}
      <TipsSheetComponent currentPageTitle={PageTitle.upload}/>
      {submitLoading &&
      <Backdrop className={classes.backdrop} open={submitLoading}>
        <CircularProgress/>
      </Backdrop>
      }
      <Snackbar
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Issue Reported, Please upload another sheet."
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            style={{ padding: "1px" }}
            onClick={handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <Grid container spacing={3} className={classes.container} style={{ background: "white" }}>
        {/* left side */}
        <Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.paper} elevation={0} style={{ background: "#F8F9FA" }}>
            <div className={classes.heading}>
              {/* <ArrowBackIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => setState("d")}/> */}
              <ArrowBackIcon onClick={handleBack} style={{ color: "#1A73E8", cursor: "pointer"}}></ArrowBackIcon>
              <Typography style={{
                paddingLeft: "10px",
                fontSize: "28px",
                fontWeight: "bold",
                lineHeight: "24px",
                letterSpacing: "0.1px",
                alignItems: "center"
              }} gutterBottom>
                Upload LPA Sheet
              </Typography>
            </div>
            <Typography variant="caption" display="block" style={{
              color: "#BDC1C6",
              paddingLeft: "34px",
              paddingBottom: "30px",
              fontSize: "18px",
              fontWeight: "bold",
              lineHeight: "24px",
              letterSpacing: "0.1px",
            }} gutterBottom>
              {uploadedFiles.length} sheets uploaded
            </Typography>

            <Grid className={classes.batchImageContainer} container spacing={4}>
              {uploadedFiles.map((row, i) => (
                <Grid item key={i} xs={12} sm={6} md={6} style={{ height: "250px" }}>
                  {/* C:\\Users\\Administrator\\Desktop\\tblpa_backend_with_ml\\tb-lpa-backend\\media\\batch\\8\\transformed_f974c520-2863-43a3-85fb-30875fb27aa9_1LAacDAOUMFf7CNInYmsYYVUn8twUqOxI.png.jpg */}
                  <img onClick={() => handleImagePreviewOpen(i)} alt="Uploaded file" src={row.transformed_image_location}
                       style={{ width: "100%", height: "100%", cursor: "pointer" }}/>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      lineHeight: "22px",
                      letterSpacing: "0.1px",
                    }}>Sheet {i + 1}</Typography>
                    <DeleteOutlineIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => {
                      setDeleteSheetId(row.sheet_id)
                    }}/>
                    <Dialog open={deleteSheetId > -1} onClose={handleClose} className={classes.dialog}>
                      <DialogContent>
                        <Typography className={classes.dialogContent}>
                          Are you sure you want to delete this sheet?
                        </Typography>
                      </DialogContent>
                      <DialogActions className={classes.dialogueActions}>
                        <Button variant="text" color="default" onClick={handleClose} className={classes.cancelButton}>
                          Cancel
                        </Button>
                        <Button onClick={handleDelete} variant="contained" className={classes.button}
                                startIcon={<DeleteOutlineIcon></DeleteOutlineIcon>}>
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </Grid>

              ))}

            </Grid>

            <div className={classes.nextStepButton}>
              {/* <Button onClick={handleBack} className={classes.button}
                      variant="contained" endIcon={<ArrowBackIcon></ArrowBackIcon>}>
                Previous Page
              </Button> */}

              <Button disabled={uploadedFiles.length < 1} onClick={handleNext} className={classes.button}
                      variant="contained" endIcon={<ArrowForwardIcon></ArrowForwardIcon>}>
                Next
              </Button>
            </div>
            
          </Paper>
        </Grid>
        {/* right side */}
        <Grid item xs={12} md={8} lg={8}>

          <Paper variant="outlined" className={classes.paper}>
            {reupload
              ? <div className={classes.rightSideDiv}>
                <PublishIcon style={{ fontSize: 100, color: "#DEE0E4" }}/>
                <div className={classes.c1}>
                  <Typography align="center" style={{
                    fontStyle: "Italic",
                    color: "#BDC1C6",
                    fontSize: "13px",
                    fontWeight: "700",
                    lineHeight: "16px",
                    letterSpacing: "0.15px"
                  }} gutterBottom>
                    Please upload sheets from the same batch.
                  </Typography>
                  <Typography align="center" style={{
                    fontStyle: "Italic",
                    color: "#BDC1C6",
                    fontSize: "13px",
                    fontWeight: "700",
                    lineHeight: "16px",
                    letterSpacing: "0.15px"
                  }} gutterBottom>
                    Ensure that the sheet is not tilted while scanning and that the scanner settings are correct
                  </Typography>
                </div>
                <Button className={classes.button} variant="contained" component="label"
                        startIcon={<PublishIcon></PublishIcon>}>
                  Upload
                  <input
                    accept="image/png"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    hidden
                    onChange={handleUploadClick}
                  />
                </Button>
                {erroDialogOpen
                  ? <ErrorDialog
                    open={erroDialogOpen}
                    onClose={handleErrorDialogClose}
                    errorHeading={errorHeading}
                    errorContent={errorContent}
                  /> : ''}
                <Typography align="center" style={{
                  fontStyle: "Italic",
                  color: "#3C4043",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  letterSpacing: "0.15px",
                  paddingTop: "10px"
                }} gutterBottom>
                  Supported file extensions: PNG
                </Typography>
              </div>
              : qcLoading
                ? <Grid> <BorderLinearProgress/></Grid>

                : <Grid container spacing={3}>

                  <Grid item xs={12} md={6} lg={6}>
                    <List component="nav" style={{ height: "590px" }}>
                      {currentFile?.sheet_quality
                        ? <img alt="current uploaded file" src={currentFile?.transformed_image_location}
                             style={{ width: "100%", height: "100%" }}/>
                        : <img alt="current uploaded file2" src={uploadedImage} style={{ width: "100%", height: "100%" }}/>}
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {currentFile.sheet_id
                      ? <List component="nav">
                        <Divider/>
                        {currentFile.sheet_quality
                          ? <React.Fragment>

                            <ListItem divider>
                              <ListItemText primary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="subtitle1"
                                    style={{
                                      color: "#BDC1C6",
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      lineHeight: "24px",
                                      letterSpacing: "0.1px"
                                    }}

                                  >
                                    Image Quality:
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    style={{
                                      color: "green",
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      lineHeight: "24px",
                                      letterSpacing: "0.1px",
                                      paddingLeft: "8px"
                                    }}
                                  >
                                    Good
                                  </Typography>
                                </React.Fragment>
                              }/>

                            </ListItem>
                            <Divider/>
                            <ListItem divider>
                              <ListItemText primary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="subtitle1"
                                    style={{
                                      color: "#BDC1C6",
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      lineHeight: "24px",
                                      letterSpacing: "0.1px"
                                    }}
                                  >
                                    Test Type:
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    style={{
                                      color: "black",
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      lineHeight: "24px",
                                      letterSpacing: "0.1px",
                                      paddingLeft: "8px"
                                    }}

                                  >
                                    {fileQualityPayload?.sheet_type === 'FL' ? "First Line LPA" : "Second Line LPA"}
                                  </Typography>
                                </React.Fragment>
                              }/>
                              <Button className={classes.reportButton}
                                      onClick={() => setReportDialog(true)}> REPORT</Button>
                              <ReportDialog reportDialog={reportDialog} sheetId={currentFile.sheet_id}
                                            setReportDialog={setReportDialog} setReupload={setReupload} setOpenSnackbar={setOpenSnackbar}/>
                            </ListItem>
                          </React.Fragment>
                          : <React.Fragment>
                            <ListItem>
                              <ListItemText primary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="#BDC1C6"
                                    style={{
                                      color: "#BDC1C6",
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      lineHeight: "24px",
                                      letterSpacing: "0.1px"
                                    }}

                                  >
                                    Image Quality:
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    style={{
                                      color: "red",
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      lineHeight: "24px",
                                      letterSpacing: "0.1px",
                                      paddingLeft: "8px"
                                    }}
                                    color="textPrimary"
                                  >
                                    Bad
                                  </Typography>
                                </React.Fragment>
                              }/>
                              <Button className={classes.reportButton}
                                      onClick={() => setReportDialog(true)}> REPORT</Button>
                              <ReportDialog reportDialog={reportDialog} sheetId={currentFile.sheet_id}
                                            setReportDialog={setReportDialog} setReupload={setReupload} setOpenSnackbar={setOpenSnackbar}/>

                            </ListItem>
                            <div style={{ paddingLeft: "20px", paddingTop: "5px", paddingBottom: "5px" }}>
                              <Typography
                                component="span"
                                variant="subtitle1"
                                style={{
                                  color: "#3C4043",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                  letterSpacing: "0.1px"
                                }}
                                color="#BDC1C6"
                              >
                                {currentFile.error_message}
                              </Typography>
                            </div>
                          </React.Fragment>
                        }
                        <Divider/>
                        <ListItem>
                          <Button className={classes.button} component="label" variant="contained"
                                  startIcon={<PublishIcon></PublishIcon>}>
                            Reupload
                            <input
                              accept="image/png"
                              className={classes.input}
                              id="contained-button-file"
                              type="file"
                              hidden
                              onChange={handleUploadClick}
                            />
                          </Button>
                          {currentFile.sheet_quality
                            ? <Button className={classes.button} variant="contained" startIcon={<DoneIcon></DoneIcon>}
                                      onClick={handleSubmit}> Submit</Button>
                            : ''}
                        </ListItem>
                      </List>
                      : ''}
                  </Grid>

                </Grid>

            }
          </Paper>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

export default Upload;
