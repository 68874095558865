import { InterpretationViewType, ScreenNames, SortingOrder } from "../constants/CustomEnums";
import React, { useEffect, useState } from 'react';
import {
  logClickOnSortOptionEvent,
  logInterpretationViewTypeClickEvent,
  logPreviewSheetEvent
} from "../analytics/analyticsHelper";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AppBar from "../components/appBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ImageCarousel from '../components/carousel'
import InterpretationTable from '../components/interpretationTable';
import { InterpretationTipsComponent } from "../components/tips/InterpretationTipsComponent";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import { submitInterpretation, getBatchValidity } from "../actions/global"

// import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    leftPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 400,
      },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 650,
      },
      container: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      dashboardContainer: {
        padding: 20,
      },
      nextStepButton: {
          display: "flex",
          paddingTop: 300,
          flexDirection: "column",
          alignItems: "center"
      },
      rightSideDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 280
    },
    c1: {
        paddingTop: 5
    },
    heading: {
        display: "flex",
        justifyContent: "flex-start",
        paddingTop: "30px",
        paddingLeft: "10px"
    },
    button: {
      margin: theme.spacing(1),
      color: theme.palette.getContrastText("#1967D2"),
      backgroundColor: "#1967D2",
      "&:hover": {
          backgroundColor: "#1967D2",
          "@media (hover: none)": {
            backgroundColor: "#1967D2"
          }
        }
  }
}));

const AntTabs = withStyles({
  indicator: {
    backgroundColor: "#1A73E8"
  },
  root: {
    borderBottom: '1px solid #BDC1C6',
  }
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    "&:hover": {
      color: "#1A73E8",
      opacity: 1
    },
    "&$selected": {
      color: "#1A73E8",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "#1A73E8"
    },
    minWidth: "308px"
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#DADCE0"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#DADCE0",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DADCE0"
      },
      "&:hover fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px"
      }
    }
  }
})(TextField);

const Interpretation = ({ setState }) => {
  // if score == 0 then review is not needed
  const dispatch = useDispatch();
  const classes = useStyles();
  // const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const [imagePreview, setImagePreview] = React.useState(false);
  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
  // const batchId = 78
  if (!batchId) {
    setState("d")
    // history.push("/dashboard")
  }
  const interpretationApiData = useSelector(state => state.globalActionsReducer.interpretation)
  const [interpretationResults, setInterpretationResults] = useState([])
  const [batchType, setBatchType] = useState("")
  const [modifiedInputArray, setModifiedInputArray] = useState([])
  const [modifiedOutputArray, setModifiedOutputArray] = useState([])

  const filterForNotReview = (arr) => {
    return arr.filter(el => el.score === 0)
  }
  const filterForReview = (arr) => {
    return arr.filter(el => el.score > 0)
  }
  const filterOnSheetId = (arr, sheetId) => {
    return arr.filter(el => el.sheetId === sheetId)
  }

  useEffect(() => {
    setInterpretationResults(interpretationApiData?.sheets ? interpretationApiData?.sheets : [])
    setBatchType(interpretationApiData?.batch_type ? interpretationApiData?.batch_type : 'FL')
  }, [interpretationApiData])

  useEffect(() => {
    const inputArray = []
    const outputArray = []

    const combineBandArrays = (interpreted, confidence) => {
      const bandData = []
      interpreted.forEach((obj, i) => {
        bandData.push({
          bandPresent: obj === 1,
          confidence: confidence[i]
        })
      })
      return bandData
    }

    const combineResultArrays = (validity, genotypic, phenotypic) => {
      const resultData = [] // 11, 14
      const size = batchType === 'FL' ? 11 : 14;
      if (validity === 'INVALID_TEST') {
        resultData.push('INVALID TEST')
      } else if (validity === 'INDETERMINATE_TEST') {
        resultData.push('INDETERMINATE TEST')
      } else if (validity === 'MTB_NOT_DETECTED') {
        resultData.push('MTB NOT DETECTED')
      }
      if (resultData[0] === 'INVALID TEST' || resultData[0] === 'INDETERMINATE TEST' || resultData[0] === 'MTB NOT DETECTED') {
        [...Array(size - 1)].map(() =>
          resultData.push('')
        )
        return resultData
      } else {
        if (batchType === 'FL') {
          genotypic.map(obj => {
            resultData.push(obj)
            return true
          })
          phenotypic.map(obj => {
            if (obj === 'S') {
              resultData.push('S')
              resultData.push('')
            } else {
              resultData.push('')
              resultData.push('R')
            }
            return true
          })
        } else {
          genotypic.map(obj => {
            resultData.push(obj)
            return true
          })
          resultData.push(phenotypic[0])
          resultData.push(phenotypic[1])
          resultData.push('')
          resultData.push('')
          resultData.push(phenotypic[2])
        }
        return resultData
      }
    }

    interpretationResults.forEach(function (sheetObject, index) {
      sheetObject.rows.forEach(rowObject => {
        if (!rowObject.is_empty) {
          inputArray.push({
            sheetNo: index + 1,
            sheetId: sheetObject.sheet_id,
            rowId: rowObject?.row_id,
            position: rowObject.position,
            score: rowObject?.score,
            stripImagePath: rowObject.strip_image_path,
            bandData: combineBandArrays(rowObject?.reviewed_band_array ? rowObject.reviewed_band_array : rowObject.interpreted_band_array, 
              rowObject.band_confidence_array)
          })

          outputArray.push({
            sheetId: sheetObject.sheet_id,
            rowId: rowObject?.row_id,
            position: rowObject.position,
            score: rowObject?.score,
            resultData: combineResultArrays(rowObject.test_validity, rowObject.genotypic_interpretation, rowObject.phenotypic_interpretation)
          })
        }
      })
    })
    setModifiedInputArray([...inputArray])
    setModifiedOutputArray([...outputArray])
  }, [batchType, interpretationResults])

  const [sortOrder, setSortOrder] = useState(0)
  useEffect(() => {
    const inputArray = [...modifiedInputArray]
    const outputArray = [...modifiedOutputArray]
    inputArray.sort((input1, input2) => {
      if (sortOrder === 0) {
        if (input1.position < input2.position) {
          return -1
        }
        return 1
      } else if (sortOrder === 1) {
        if (input1.position > input2.position) {
          return -1
        }
        return 1
      } else {
        if (input1.score > input2.score) {
          return -1
        }
        return 1
      }
    })
    outputArray.sort((input1, input2) => {
      if (sortOrder === 0) {
        if (input1.position < input2.position) {
          return -1
        }
        return 1
      } else if (sortOrder === 1) {
        if (input1.position > input2.position) {
          return -1
        }
        return 1
      } else {
        if (input1.score > input2.score) {
          return -1
        }
        return 1
      }
    })
    setModifiedInput([...inputArray])
    setModifiedOutput([...outputArray])
  }, [sortOrder, modifiedInputArray, modifiedOutputArray])

  const [modifiedInput, setModifiedInput] = React.useState([])
  const [modifiedOutput, setModifiedOutput] = React.useState([])
  const [selectedSheetId, setSelectedSheetId] = React.useState(interpretationResults.length ? interpretationResults[0]?.sheet_id : '-1')

  useEffect(() => {
    setSelectedSheetId(interpretationResults.length ? interpretationResults[0]?.sheet_id : '-1')
  }, [interpretationResults])

  function convertToArray (arr) {
      const output = []
      arr.map(i => {
        output.push(i.bandPresent ? 1 : 0)
        return true
      })
      return output
  }
  function onImageClick (sheetId) {
    setSelectedSheetId(sheetId)
  }
  function onSummaryClick () {
    const updatedRows = []
    modifiedInput.map(obj => {
      updatedRows.push({
        row_id: obj.rowId,
        reviewed_band_array: convertToArray(obj.bandData)
      })
      return true
    })
    dispatch(submitInterpretation(batchId, updatedRows)).then(() => { setState("s") })
  }

  const handleChange = (event, newValue) => {
    logInterpretationViewTypeClickEvent(newValue === 0 ? InterpretationViewType.PRIORITISATION_VIEW : InterpretationViewType.SHEET_VIEW)
    setValue(newValue);
  };

  const [selectedImageIndexForPreview, setSelectedImageIndexForPreview] = useState(0)
  const handleImagePreviewOpen = (index) => {
    logPreviewSheetEvent(ScreenNames.INTERPRETATION)
    setImagePreview(true);
    setSelectedImageIndexForPreview(index)
  };

  const handleImagePreviewClose = () => {
    setImagePreview(false);
  };

  const createAscOrderText = () => {
    const rowIds = modifiedInput.filter(value => value.sheetId === selectedSheetId).map(value => value.position)
    // For cases when rowIds is empty
    if (rowIds.length === 0) return `Sr. No (0 - 0)`
    const minValue = Math.min(...rowIds)
    const maxValue = Math.max(...rowIds)
    return `Sr. No (${minValue} - ${maxValue})`
  }

  const createDescOrderText = () => {
    const rowIds = modifiedInput.filter(value => value.sheetId === selectedSheetId).map(value => value.position)
    // For cases when rowIds is empty
    if (rowIds.length === 0) return `Sr. No (0 - 0)`
    const minValue = Math.min(...rowIds)
    const maxValue = Math.max(...rowIds)
    return `Sr. No (${maxValue} - ${minValue})`
  }
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value)
    logClickOnSortOptionEvent(event.target.value === 0 ? SortingOrder.ASCENDING : event.target.value === 1 ? SortingOrder.DESCENDING : SortingOrder.STRIPS_TO_REVIEW_FIRST)
  }

  const createTabHeader = (header = "Prioritisation View", description = "Focus on strips where the algorithm is not sure") => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start", fontSize: "16px", alignContent: "flex-start", fontWeight: "500" }}>
        <div>{header}</div>
        <span style={{ fontSize: "12px", color: "#202124", textAlign: "start", fontWeight: "400", lineHeight: "1.2" }}>{description}</span>
      </div>
    )
  }

  function handleBack () {
    dispatch(getBatchValidity(batchId)).then(() => { setState('b') })
  }

  return (
    <div>
      <CssBaseline />
      <AppBar />
      <InterpretationTipsComponent />
      {imagePreview && <ImageCarousel
        open={imagePreview}
        onClose={handleImagePreviewClose}
        images={interpretationResults.map(row => row.transformed_image_location)}
        index={selectedImageIndexForPreview}
      />}
      <Grid container className={classes.dashboardContainer} style={{ background: "white" }}>
        <Paper elevation={0} style={{ width: "100%", height: value === 1 ? "400px" : "150px", backgroundColor: "#F8F9FA" }}>
            <div className={classes.heading}>
              {/* <ArrowBackIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => setState("v")}/> */}
              <ArrowBackIcon onClick={handleBack} style={{ color: "#1A73E8", cursor: "pointer"}}></ArrowBackIcon>
              <Typography style={{ paddingLeft: "15px", fontSize: "28px", fontWeight: "bold", lineHeight: "24px", letterSpacing: "0.1px", }} gutterBottom>
              Review Interpretation
              </Typography>
            </div>
            <div style={{ paddingLeft: "30px" }}>
              <Grid container>
                <Grid item xs = {9}>
                  <AntTabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange} >
                    <AntTab label={createTabHeader("Prioritisation View", "Focus on strips where the algorithm is not sure")}/>
                    <AntTab label={createTabHeader("Sheet View", "See all your strips in order of serial number")}/>
                  </AntTabs>
                </Grid>
                {value === 1 && <Grid item xs = {3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CssTextField
                    select
                    size="small"
                    variant="outlined"
                    value={sortOrder}
                    onChange={e => handleSortOrderChange(e)}
                    style={{ width: "204px", margin: 8 }}
                  >
                    <MenuItem key={0} value={0}>{createAscOrderText()}</MenuItem>
                    <MenuItem key={1} value={1}>{createDescOrderText()}</MenuItem>
                    <MenuItem key={2} value={2}>View strips to review first</MenuItem>
                  </CssTextField>
                </Grid>}
              </Grid>
            </div>
            {value === 1
              ? <Grid container style={{ display: "flex", justifyContent: "center", paddingTop: "20px", paddingBottom: "20px" }} spacing={2}>
                {interpretationResults.map((i, j) => (
                    <Grid item style={{ width: "140px", height: "200px", alignItems: "center", display: "flex", flexDirection: "column" }}>
                      <div style={{ height: "150px" }}>
                      <img onClick={() => onImageClick(i.sheet_id)} alt="scale" style={{ width: "100%", height: "100%", border: "1px solid #BDC1C6", borderRadius: "3px", cursor: "pointer" }} src={i.transformed_image_location}/>
                      {selectedSheetId === i.sheet_id && <Paper variant="outlined" style={{ backgroundColor: "green", height: "8px" }}/>}
                      </div>
                      <Typography style={{ paddingTop: "15px", color: "#3C4043", fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px" }}>
                          {i.rows.length} strips
                      </Typography>
                      <Typography style={{ color: "orange", fontSize: "14px", fontWeight: "bold", lineHeight: "22px", letterSpacing: "0.1px" }}>
                        (Review {i.rows.filter(row => row.score > 0).length} Strip)
                      </Typography>
                      <Typography style={{ color: "#80868B", fontSize: "12px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px", textDecorationLine: "underline", cursor: 'pointer' }} onClick={() => handleImagePreviewOpen(j)}>
                        View Sheet
                      </Typography>
                    </Grid>
                ))}

              </Grid>
              : ''
              }
        </Paper>

        {value === 0
          ? <Grid container style={{ paddingTop: "10px" }}>
                <Accordion id="1" elevation={0} square expanded={expanded === "panel1"} onChange={handleAccordionChange("panel1")} >
                  <AccordionSummary >
                    <div style={{ paddingLeft: "20px" }}> </div>
                    { expanded === "panel1" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                    <Typography style={{ fontSize: "20px", fontWeight: "500", lineHeight: "28px", letterSpacing: "0.1px", }}>All Strips flagged for review ({filterForReview(modifiedInput).length})</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <div style={{ paddingBottom: "20px", paddingTop: "20px", borderTop: "1px solid #E8EAED" }}>
                    <InterpretationTable batchType={batchType} input={modifiedInputArray} output={modifiedOutputArray} setInput={setModifiedInputArray} setOutput={setModifiedOutputArray} inputToDisplay={filterForReview(modifiedInputArray) } outputToDisplay={filterForReview(modifiedOutputArray) }/>
                  </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion id="2" elevation={0} square expanded={expanded === "panel2"} onChange={handleAccordionChange("panel2")} >
                  <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <div style={{ paddingLeft: "20px" }}> </div>
                    {expanded === "panel2" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                    <Typography style={{ fontSize: "20px", fontWeight: "500", lineHeight: "32px", letterSpacing: "0.1px", }}>All Strips where algorithm has high confidence ({filterForNotReview(modifiedInput).length })</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <div style={{ paddingBottom: "20px", paddingTop: "20px", borderTop: "1px solid #E8EAED" }}>
                  <InterpretationTable batchType={batchType} input={modifiedInputArray} output={modifiedOutputArray} setInput={setModifiedInputArray} setOutput={setModifiedOutputArray} inputToDisplay={filterForNotReview(modifiedInputArray) } outputToDisplay={filterForNotReview(modifiedOutputArray) }/>
                  </div>
                  </AccordionDetails>
                </Accordion>
              <Grid container direction="row" display="flex" justify="center" style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Button variant="contained" onClick={onSummaryClick} style={{
                                width: "200px",
                                height: "40px",
                                fontSize: "16px",
                                color: "#FFFFFF",
                                backgroundColor: "#1967D2",
                                borderRadius: "100px",
                                "&:hover": {
                                    backgroundColor: "#1967D2",
                                    "@media (hover: none)": {
                                        backgroundColor: "#1967D2"
                                    }
                                    }
                            }}>View Summary</Button>
                {/* <Button onClick={handleBack} className={classes.button}
                      variant="contained" endIcon={<ArrowBackIcon></ArrowBackIcon>}>
                Previous
              </Button> */}
              </Grid>
          </Grid>
          : <Grid>
              <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
              <InterpretationTable batchType={batchType} input={modifiedInput} output={modifiedOutput} setInput={setModifiedInput} setOutput={setModifiedOutput} inputToDisplay={filterOnSheetId(modifiedInput, selectedSheetId) } outputToDisplay={filterOnSheetId(modifiedOutput, selectedSheetId) }/>
              </div>

              <Grid container direction="row" display="flex" justify="center" style={{ marginTop: "30px", marginBottom: "30px" }}>
                <Button className={classes.button} variant="contained" onClick={onSummaryClick}>View Interpretation Summary</Button>
                {interpretationResults.length > 1
                ? <React.Fragment>

                <IconButton disabled={interpretationResults.findIndex(obj => obj.sheet_id === selectedSheetId) <= 0}
                onClick= {() => setSelectedSheetId(interpretationResults[interpretationResults.findIndex(obj => obj.sheet_id === selectedSheetId) - 1].sheet_id)}
                className={classes.button} variant="contained">
                  <ArrowBackIcon/>
                </IconButton>
                <IconButton disabled={selectedSheetId === -1 || interpretationResults.findIndex(obj => obj.sheet_id === selectedSheetId) >= interpretationResults.length - 1}
                onClick= {() => setSelectedSheetId(interpretationResults[interpretationResults.findIndex(obj => obj.sheet_id === selectedSheetId) + 1].sheet_id)}
                className={classes.button} variant="contained" >
                  <ArrowForwardIcon/>
                </IconButton> </React.Fragment> : ''}
                {/* <Button className={classes.button} variant="contained" endIcon={<ArrowRightAltOutlinedIcon></ArrowRightAltOutlinedIcon>}> Next Sheet</Button> */}
              </Grid>
          </Grid>
        }

      </Grid>

    </div>
  );
}

export default Interpretation;
