import { DialogContent, Typography } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: theme.palette.getContrastText("#1967D2"),
    backgroundColor: "#1967D2",
    "&:hover": {
        backgroundColor: "#1967D2",
        "@media (hover: none)": {
          backgroundColor: "#1967D2"
        }
      }
}
}));

export default function ErrorDialog ({ onClose, open, errorHeading, errorContent }) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{errorHeading}</DialogTitle>
      <DialogContent style={{ paddingBottom: "20px" }}>
        <Typography style={{ paddingBottom: "10px", color: "#3C4043", fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px" }}>
            {errorContent}
        </Typography>
        <Button className={classes.button} variant="contained" onClick={onClose} style={{ width: "100px", marginLeft: "-2px" }}> Okay </Button>
      </DialogContent>

    </Dialog>
  );
}

ErrorDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorHeading: PropTypes.string.isRequired,
  errorContent: PropTypes.string.isRequired,
};
