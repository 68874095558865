import { combineReducers } from "redux";
import globalActionsReducer from "./global";
import loginActionsReducer from "./login";

const appReducer = combineReducers({
  loginActionsReducer,
  globalActionsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT" || action.type === "START_NEW") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
