import { getInterpretation, getSummary, getBatchRoi } from "../actions/global"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'

import AppBar from "../components/appBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import DraggableStrip from '../components/draggableStrip';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import { PageTitle } from "../constants/app-contants";
import Paper from '@material-ui/core/Paper';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { TipsSheetComponent } from "../components/tips/TipsSheetComponent";
import Typography from '@material-ui/core/Typography';
import { alpha } from "@material-ui/core/styles/colorManipulator";

// import { useHistory } from 'react-router-dom';

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#BDC1C6"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#BDC1C6",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#BDC1C6"
      },
      "&:hover fieldset": {
        borderColor: "#BDC1C6",
        borderWidth: "1px"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#BDC1C6",
        borderWidth: "1px"
      }
    }
  }
})(TextField);

const useStyles = makeStyles((theme) => ({
    tip: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: "300px",
      },
    paper: {
        padding: theme.spacing(2),
        elevation: "0",
        display: 'flex',
        overflow: 'auto',
        height: 680,
        // width: theme.spacing(80),
        background: "#FFFFFF"
      },
      container: {
        padding: 40,
        background: "#FFFFFF"
      },
    heading: {
        display: "flex",
        margin: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
      color: theme.palette.getContrastText("#1967D2"),
      backgroundColor: "#1967D2",
      "&:hover": {
          backgroundColor: "#1967D2",
          "@media (hover: none)": {
            backgroundColor: "#1967D2"
          }
        }
  },
      reportButton: {
        color: "#1967D2",
        "&:hover": {
            backgroundColor: alpha("#1967D2", theme.palette.action.hoverOpacity),
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
            backgroundColor: "transparent"
            }
        },
        justifyContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "flex-end"
    },
    formControl: {
    margin: theme.spacing(0, 0, 0, 1),
  },

}));

const BatchValidity = ({ setState }) => {
  const [pos, setPos] = React.useState(0)
  const dispatch = useDispatch();
    const classes = useStyles();
    // const history = useHistory();
    const [changeValidity, setChangeValidity] = React.useState(false);
    const [updateReason, setUpdateReason] = React.useState('');

    const handleDropDownChange = (event) => {
        setBatchValidity(event.target.value);
      };
  const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
  const batchValidityData = useSelector(state => state.globalActionsReducer.batch_Validity_data)
  const [batchValidity, setBatchValidity] = React.useState(batchValidityData?.is_valid_batch ? 1 : 0);
  const batchType = batchValidityData?.batch_type;
  const validText = 'The conditions for control strips are valid. Please click "Get Interpretation" to see the results'
  const invalidText = 'The conditions for control strips has failed. Please click "View Results" to see the results. You do not need to continue with interpretations'
  // console.log("batch validity page", batchValidityData)
  if (!batchId) {
    setState("d")
    // history.push("dashboard")
  }
  function getInterpretationResults () {
    if (batchValidity === 1) {
      dispatch(getInterpretation(batchId, true, updateReason)).then(() => { setState("i") })
    } else {
      dispatch(getSummary(batchId, false, updateReason, true)).then(() => { setState('s') })
    }
  }
  function getControlStripContent (text1, text2, stripImage, textToDisplay, index) {
        return <Grid item xs={12} key={index}>
          <DraggableStrip pos={pos} setPos={setPos} image={stripImage}></DraggableStrip>
                        <div style={{ paddingLeft: "60px", display: "flex" }}>
                        <Typography style={{ fontSize: "18px", fontWeight: "700", lineHeight: "24px", letterSpacing: "0.1px" }} >
                            {text1}
                        </Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px", marginLeft: 5 }} >
                            ( {text2}: {textToDisplay})
                        </Typography>
                        </div>
                </Grid>
  }

  function getValue (bool) {
    return bool ? 'Success' : 'Failure';
  }
  function getTextToDisplay (stripType, status) {
    if (stripType === 'positive control') {
      return status ? 'AC,CC and TUB bands are identified' : 'AC/CC/TUB not identified';
    } else {
      return status ? 'AC and CC bands are identified' : 'AC/CC band absent or presence of other bands';
    }
  //   if (arr) {
  //   let text = ''
  //   if (arr[0] === 0 && arr[1] === 0 && arr[2] === 0) return 'CC/AC/TUB bands not identified'
  //   if (arr[0] === 1) { text = text + 'CC/' }
  //   if (arr[1] === 1) { text = text + 'AC/' }
  //   if (arr[2] === 1) { text = text + 'TUB' }
  //   if (text[text.length - 1] === '/') {
  //     text = text.slice(0, -1)
  //   }
  //   return text + ' bands identified'
  // }
  }

  function getTag (text) {
    if (text === 'positive control') { return 'Positive Control' } else if (text === 'extraction control') { return 'Extraction Control' } else if (text === 'mastermix control') { return 'Mastermix Control' }
  }

  function handleBack(){
    dispatch(getBatchRoi(batchId)).then(() => {
      setState("m")
    })
  }


  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar />
      <TipsSheetComponent currentPageTitle={PageTitle.batchValidity}/>
            <Grid container justifyContent="center" spacing={3} className={classes.container} >
                <Grid item xs={12} md={5} lg={5} >
                    <Paper elevation={0} style={{ overflow: "auto", height: "auto", background: "#F8F9FA" }} >
                    <List className={classes.root}>
                    <ListItem >
                        <div className={classes.heading}>
                        {/* <ArrowBackIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => history.push("/matchLabIds")}/> */}
                        <ArrowBackIcon onClick={handleBack} style={{ color: "#1A73E8", cursor: "pointer"}}></ArrowBackIcon>
                        <Typography style={{ fontSize: "28px", fontWeight: "bold", lineHeight: "24px", letterSpacing: "0.1px", paddingLeft: "15px"}} >
                        Check batch validity
                        </Typography>
                        </div>
                    </ListItem>
                    <Divider variant="middle"/>
{changeValidity
? <div>
  <ListItem style={{ paddingLeft: "20px", display: "flex" }}>
                        <Typography style={{ paddingRight: "10px", fontSize: "18px", fontWeight: "bold", lineHeight: "24px", letterSpacing: "0.1px", color: "#BDC1C6" }} >
                            Control Strips:
                        </Typography>
        <CssTextField
          select
          size="small"
          variant="outlined"
          value={batchValidity}
          defaultValue={1}
          onChange={handleDropDownChange}
          style={{ width: "200px", color: "black" }}
          margin="dense"
          SelectProps={{
            displayEmpty: true

          }}
        >
          <MenuItem key={1} value={1}>
          Valid
          </MenuItem>
          <MenuItem key={0} value={0}>Invalid</MenuItem>
        </CssTextField>
                        </ListItem>
                        <ListItem style={{ paddingLeft: "20px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <CssTextField multiline rows={2} variant="outlined" fullWidth placeholder="Enter reason for change here..." value={updateReason} onChange={(event) => { setUpdateReason(event.target.value) }}/>
                        <Button className={classes.button} onClick={() => { setChangeValidity(false) }}> Save</Button>
                        </ListItem>

</div>
: <div>
 <ListItem style={{ paddingLeft: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div style={{ display: "flex" }}>
                        <Typography style={{ fontSize: "18px", fontWeight: "bold", lineHeight: "24px", letterSpacing: "0.1px", color: "#BDC1C6" }} >
                            Control Strips:
                        </Typography>
                        <Typography style={{ fontSize: "18px", fontWeight: "bold", lineHeight: "24px", letterSpacing: "0.1px", marginLeft: 5, color: batchValidity === 0 ? "red" : "green" }} >
                        {batchValidity === 0 ? "Invalid" : "Valid"}
                        </Typography>
                         </div>
                        <Button className={classes.reportButton} onClick={() => { setChangeValidity(true); }}> CHANGE</Button>
                        </ListItem>
                        <ListItem style={{ paddingLeft: "20px", display: "flex", width: "400px", paddingBottom: "20px" }}>
                        <Typography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px" }} >
                        {batchValidity === 0 ? invalidText : validText}
                            </Typography>

                        </ListItem>
</div>

}

                        <Divider variant="middle"/>

                        <ListItem style={{ paddingLeft: "20px", display: "flex", width: "400px" }}>
                    <Button onClick={getInterpretationResults} className={classes.button} variant="contained"> {batchValidity === 0 ? "View Results" : "Get interpretations"} </Button>
                    {/* <Button onClick={handleBack} className={classes.button}
                      variant="contained" endIcon={<ArrowBackIcon></ArrowBackIcon>}>
                      Previous
                    </Button> */}
                        </ListItem>
                    </List>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Paper variant="outlined" elevation={0} className={classes.paper} >
                    <Grid container>
                      <Grid item xs={12} style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                        {batchType === 'SL'
                        ? <img alt="scale" style={{ width: "100%", height: "100%" }} src={require('../constants/ref_strip_SL.png')}></img>
                        : <img alt="scale" style={{ width: "100%", height: "100%" }} src={require('../constants/ref_strip_FL.png')}></img> }

                        </Grid>
                        {
                          batchValidityData?.control_strips?.map((a, index) => (
                            getControlStripContent(getTag(a?.strip_type), getValue(a?.is_valid), a?.strip_image_path, getTextToDisplay(a?.strip_type, a?.is_valid), index)
                          ))
}

                    </Grid>

                    </Paper>
                </Grid>
            </Grid>

    </React.Fragment>
  );
}

export default BatchValidity;
