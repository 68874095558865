import { makeStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { reportSheet } from "../actions/global"
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText("#1967D2"),
        backgroundColor: "#1967D2",
        "&:hover": {
            backgroundColor: "#1967D2",
            "@media (hover: none)": {
              backgroundColor: "#1967D2"
            }
          }
    }
}));

const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "#BDC1C6"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#BDC1C6",
        borderWidth: "1px"
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#BDC1C6"
        },
        "&:hover fieldset": {
          borderColor: "#BDC1C6",
          borderWidth: "1px"
        },
        "&.Mui-focused fieldset": {
          borderColor: "#BDC1C6",
          borderWidth: "1px"
        }
      }
    }
  })(TextField);

const ReportDialog = ({ reportDialog, sheetId, setReportDialog = () => {}, setReupload = () => {}, setOpenSnackbar = () => {} }) => {
    const dispatch = useDispatch();
    const text = "If you experience this problem multiple time please report it to us. We will review the issue. "
    const textWrongTestType = "If the test type detected for this sheet is incorrect, please report this to us. "
    const nextStepsText = "Next steps: Unfortunately we will not be able to continue interpretation for this sheet. Please upload any other sheets you may want the system to interpret    "
    const classes = useStyles();
    const [reportingOption, setReportingOption] = React.useState(0);
    const [reportReason, setReportReason] = React.useState("")
    function callReport () {
        dispatch(reportSheet(sheetId, reportReason))
        setReupload(1)
        setOpenSnackbar(true)
        setReportDialog(false)
    }

  return (
    <React.Fragment>
        <Dialog open= {reportDialog} onClose={() => setReportDialog(false) } style={{ paper: { borderRadius: 0 } }}>
        <CloseIcon style={{ color: "#5F6368", cursor: "pointer", float: 'right', alignSelf: 'flex-end', marginTop: "12", marginRight: "12" }} onClick={() => setReportDialog(false)}/>
            <Paper elevation={0} style={{ width: "428px", height: "450px", display: "flex", marginLeft: "20px", marginRight: "20px", flexDirection: "column" }}>
            <Typography style={{ fontSize: "28px", fontWeight: "bold", lineHeight: "24px", letterSpacing: "0.1px", alignItems: "center", paddingBottom: "10px" }} gutterBottom>
                Report a problem
            </Typography>
            <CssTextField
            select
            size="small"
            variant="outlined"
            value={reportingOption}
            defaultValue={1}
            onChange={(event) => { setReportingOption(event.target.value) }}
            style={{ width: "240px", color: "black" }}
            margin="dense"
            SelectProps={{
                displayEmpty: true
            }}
            >
                <MenuItem key={0} value={0}>Bad Image Quality </MenuItem>
                <MenuItem key={1} value={1}>Wrong Test Type detected</MenuItem>
            </CssTextField>
            <div style={{ width: "350px", paddingTop: "10px", paddingBottom: "10px" }}>
              <Typography style={{ color: "#3C4043", fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px" }}>
                {reportingOption === 0 ? text : textWrongTestType}
              </Typography>
              <Typography style={{ paddingTop: "20px", color: "#3C4043", fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px" }}>
                {nextStepsText}
              </Typography>
            </div>
            <CssTextField value={reportReason} onChange={(event) => { setReportReason(event.target.value) }} style={{ paddingTop: "10px", paddingBlock: "10px" }} multiline rows={4} variant="outlined" fullWidth placeholder="Write additional details here..."/>
            <Button className={classes.button} onClick={callReport} style={{ width: "125px" }}>Report</Button>
            </Paper>
        </Dialog>
    </React.Fragment>
  );
}

export default ReportDialog;
