const tipsObject = {
  dashboard: {
    headerText: "Checklist for uploading scanned sheets",
    optionalListOfInstructions: [
      {
        bulletPoint: null,
        imageSource: require("../constants/01woTEXT.gif"),
      },
    ],
  },
  matchLabIds: {
    headerText: "Important Instructions",
    optionalListOfInstructions: [
      {
        bulletPoint: "If there is no lab ID leave the row blank",
        imageSource: require("../constants/02AwoTEXT.gif"),
      },
      {
        bulletPoint: "Mark out 3 control stips to proceed",
        imageSource: require("../constants/02BwoTEXT.gif"),
      },
    ],
  },
  batchValidity: {
    headerText: "Guides can help you read the strip easily!",
    optionalListOfInstructions: [
      {
        bulletPoint: "Hover over a strip to activate guides for easy reading",
        imageSource: require("../constants/03woTEXT.gif"),
      },
    ],
  }
};

export const PageTitle = {
  upload: {
    name: "upload",
    tipsObject: tipsObject.dashboard
  },
  matchLabIds: {
    name: "matchLabIds",
    tipsObject: tipsObject.matchLabIds
  },
  batchValidity: {
    name: "batchValidity",
    tipsObject: tipsObject.batchValidity
  }
}
